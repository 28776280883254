// import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BronzeImg from './../../Public/Image/Game/bronze.svg'
import SilverImg from './../../Public/Image/Game/Silver.svg'
import GoldImg from './../../Public/Image/Game/Gold.svg'
import PlatinumImg from './../../Public/Image/Game/Platinum.svg'
import DimondImg from './../../Public/Image/Game/Dimond.svg'
import EpicImg from './../../Public/Image/Game/Epic.svg'
import LegendryImg from './../../Public/Image/Game/Legendry.svg'
import MasterImg from './../../Public/Image/Game/Master.svg'
import GrandMasterImg from './../../Public/Image/Game/GrandMaster.svg'
import LordImg from './../../Public/Image/Game/Lord.svg'
import CreatorImg from './../../Public/Image/Game/Creator.svg'
// import { SetLeagueLimitJoinProgress } from '../../app/Game'
// import {SetClaimRewardCall} from '../../app/Game'
import LinearProgress from '@mui/material/LinearProgress';
import { OnlineRequestAppSettingGame } from '../../GameAction/OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from '../../GameAction/OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from '../../GameAction/OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from '../../GameAction/OnlineRequestTopJoinerGame'
import { CheckDataBaseGame } from '../../GameAction/CheckDataBaseGame'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';

import { GameDB } from './../../DataBase/GameDB'
import React, { useEffect, useState, useRef } from 'react'

import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState, SetIsBetweenLeaguge
} from './../../app/Game'
const InnerGame = () => {
    const [ForStyle, setForStyle] = useState('')
    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const LeagueLimitJoinProgress = useSelector((state) => state.Game.LeagueLimitJoinProgress)
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    const [Progress, setProgress] = React.useState(0);
    const [RemainJoin, setRemainJoin] = useState('')
    const [LoadState, setLoadState] = useState(false)
    const [IsCreator1, setIsCreator1] = useState(false)
    const [Rank, setRank] = useState('')
    const [League, setLeague] = useState('')
    const LeagueMinimumJoinProgress = useSelector((state) => state.Game.LeagueMinimumJoinProgress)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
    const dispatch = useDispatch()
    const [LastUpdate, setLastUpdate] = useState(0)
    const IsBetweenLeaguge = useSelector((state) => state.Game.IsBetweenLeaguge)

    function abbreviate_number(num) {
        if (num === null || num === undefined || num === false) return null;
        if (num === 0) return '0';
    
        let k = Math.floor((Math.log10(num)) / 3); // تعیین مقیاس (K, M, B, T)
        let scale = Math.pow(10, k * 3); // مقدار مقیاس برای تقسیم عدد
        let shortened = num / scale; // عدد را مقیاس‌بندی می‌کنیم
    
        return shortened.toFixed(1).replace(/\.0$/, '') + ['', 'K', 'M', 'B', 'T'][k]; // حذف `.0` از اعداد صحیح
    }
    
    useEffect(() => {
    

        if (UserLeagueData.userInformation) {

            //    dispatch(JoinCountRedux(UserLeagueData.userInformation.joinCount))
        }
       
    }, [IsBetweenLeaguge, UserLeagueData])

    useEffect(() => {
        setLoadState(true)

        if (MyLeagueRank.indexOf('Bronze') >= 0) {
            setLeague('Bronze')
            setForStyle(Style.Bronze)
            var rank = MyLeagueRank.replace('Bronze', '');
            setRank(rank)
            setIsCreator1(false)
        } else if (MyLeagueRank.indexOf('Silver') >= 0) {
            setLeague('Silver')
            setForStyle(Style.Silver)
            var rank = MyLeagueRank.replace('Silver', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Gold') >= 0) {
            setLeague('Gold')
            setForStyle(Style.Gold)
            var rank = MyLeagueRank.replace('Gold', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Platinum') >= 0) {
            setLeague('Platinum')
            setForStyle(Style.Platinum)
            var rank = MyLeagueRank.replace('Platinum', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Diamond') >= 0) {
            setLeague('Dimond')
            setForStyle(Style.Dimond)
            var rank = MyLeagueRank.replace('Diamond', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Epic') >= 0) {
            setLeague('Epic')
            setForStyle(Style.Epic)
            var rank = MyLeagueRank.replace('Epic', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Legendary') >= 0) {
            setLeague('Legendary')
            setForStyle(Style.Legendary)
            var rank = MyLeagueRank.replace('Legendary', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Master') >= 0) {
            setLeague('Master')
            setForStyle(Style.Master)
            var rank = MyLeagueRank.replace('Master', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Grandmaster') >= 0) {
            setLeague('Grandmaster')
            setForStyle(Style.Grandmaster)
            var rank = MyLeagueRank.replace('Grandmaster', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Lord') >= 0) {
            setLeague('Lord')
            setForStyle(Style.Lord)
            var rank = MyLeagueRank.replace('Lord', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Creator') >= 0) {
            setLeague('Creator')
            setForStyle(Style.Creator)
            var rank = MyLeagueRank.replace('Creator', '');
            setRank(rank)
         
            if (rank == ' I') {
                setIsCreator1(true)
            } else {
                setIsCreator1(false)

            }

        }
        setTimeout(() => {
            setLoadState(false)
        }, 1000);


    }, [MyLeagueRank, UserLeagueData])
    const Style = {
        Bronze: {
            img: BronzeImg,
            Rank: '#682920',
            Progress: '#BF8970',
            ListBorder: '#BF8970',
            ListTitleBg: '#BF8970',
            ListTitleText: '#321203',
            ListTitleYou: '#4B2714',
            ListTitleYouBg: '#FFEDE6',
            ListBodyBg: '#FFEDE6',
            ListBodyText: '#321203'
        },
        Silver: {
            img: SilverImg,
            Rank: '#5E7081',
            Progress: '#8E9FAF',
            ListBorder: '#8E9FAF',
            ListTitleBg: '#8E9FAF',
            ListTitleText: '#22323F',
            ListTitleYou: '#22323F',
            ListTitleYouBg: '#F3F3F3',
            ListBodyBg: '#F3F3F3',
            ListBodyText: '#384956'
        },
        Gold: {
            img: GoldImg,
            Rank: '#8F4F2C',
            Progress: '#FFD100',
            ListBorder: '#FFD100',
            ListTitleBg: '#FFD100',
            ListTitleText: '#3C2F00',
            ListTitleYou: '#3C2F00',
            ListTitleYouBg: '#FFF0C9',
            ListBodyBg: '#FFF0C9',
            ListBodyText: '#564500'
        },
        Platinum: {
            img: PlatinumImg,
            Rank: '#373D37',
            Progress: '#ACB2AC',
            ListBorder: '#ACB2AC',
            ListTitleBg: '#ACB2AC',
            ListTitleText: '#2C322E',
            ListTitleYou: '#2C322E',
            ListTitleYouBg: '#DEE4DD',
            ListBodyBg: '#DEE4DD',
            ListBodyText: '#424844'
        },
        Dimond: {
            img: DimondImg,
            Rank: '#24B6CA',
            Progress: '#B9F2FF',
            ListBorder: '#B9F2FF',
            ListTitleBg: '#B9F2FF',
            ListTitleText: '#00363E',
            ListTitleYou: '#2C322E',
            ListTitleYouBg: '#EDFCFF',
            ListBodyBg: '#EDFCFF',
            ListBodyText: '#0B4E59'
        },
        Epic: {
            img: EpicImg,
            Rank: '#42017E',
            Progress: '#8C49CA',
            ListBorder: '#8C49CA',
            ListTitleBg: '#8C49CA',
            ListTitleText: '#FAECFF',
            ListTitleYou: '#4A007F',
            ListTitleYouBg: '#FAECFF',
            ListBodyBg: '#FAECFF',
            ListBodyText: '#4A007F'
        },
        Legendary: {
            img: LegendryImg,
            Rank: '#851212',
            Progress: '#C00021',
            ListBorder: '#C00021',
            ListTitleBg: '#C00021',
            ListTitleText: '#FFEDEB',
            ListTitleYou: '#68000D',
            ListTitleYouBg: '#FFEDEB',
            ListBodyBg: '#FFEDEB',
            ListBodyText: '#68000D'
        },
        Master: {
            img: MasterImg,
            Rank: '#2424CD',
            Progress: '#5656FF',
            ListBorder: '#5656FF',
            ListTitleBg: '#5656FF',
            ListTitleText: '#FFFBFF',
            ListTitleYou: '#1300A9',
            ListTitleYouBg: '#F2EFFF',
            ListBodyBg: '#F2EFFF',
            ListBodyText: '#00006E'
        },
        Grandmaster: {
            img: GrandMasterImg,
            Rank: '#1C1C86',
            Progress: '#0202D3',
            ListBorder: '#0202D3',
            ListTitleBg: '#0202D3',
            ListTitleText: '#E0E0FF',
            ListTitleYou: '#0202A9',
            ListTitleYouBg: '#E0E0FF',
            ListBodyBg: '#E0E0FF',
            ListBodyText: '#0202A9'
        },
        Lord: {
            img: LordImg,
            Rank: '#112E1F',
            Progress: '#006C44',
            ListBorder: '#006C44',
            ListTitleBg: '#006C44',
            ListTitleText: '#C0FFD7',
            ListTitleYou: '#005232',
            ListTitleYouBg: '#C0FFD7',
            ListBodyBg: '#C0FFD7',
            ListBodyText: '#005232'
        },
        Creator: {
            img: CreatorImg,
            Rank: '#4E4E4E',
            Progress: '#212121',
            ListBorder: '#212121',
            ListTitleBg: '#212121',
            ListTitleText: '#FFFFFF',
            ListTitleYou: '#212121',
            ListTitleYouBg: '#FFFFFF',
            ListBodyBg: '#E0E0E0',
            ListBodyText: '#212121'
        },
    }
    useEffect(() => {
   
        if (JoinCountRedux >= 0) {
            let Count = JoinCountRedux

            let percentage = ((Count - LeagueMinimumJoinProgress) / (LeagueLimitJoinProgress - LeagueMinimumJoinProgress)) * 100
         
            if (Count >= LeagueLimitJoinProgress) {
                setProgress(100)
                setRemainJoin('Completed')
            } else {
                setProgress(percentage)
            }
      
            let ProgressFinal = LeagueLimitJoinProgress - Count
       

            if (ProgressFinal > 0) {
           
                setRemainJoin(ProgressFinal)
                // setRemainJoin(0)

            } else {
                setRemainJoin(0)

            }
        } else {
            const timer = setInterval(async () => {

             
                if (JoinCountRedux >= 0) {
                    clearInterval(timer);

                } else {
                    
                }

            }, 1000);
        }

    }, [JoinCountRedux, LeagueLimitJoinProgress])

    return (
        <>
            {

                IsCreator1 ?

                    <div className='GameCoinItemCreator'>
                        <img src={CreatorImg} />
                        <div className='CreatorTextInCoin'>
                            CREATOR I
                        </div>

                    </div>
                    :
                    <>
                        <div className='GameCoinItem'>
                            <div className='GameCoinItemInner'>
                                <div className='GameCoinLeagueIcon'><img src={ForStyle.img} /></div>
                                <div className='GameCoinLeagueTitle'>{MyLeagueRank}</div>
                                <div className='GameCoinLeagueArrow'><ChevronRightIcon className='GameCoinLeagueArrow1' /></div>
                            </div>
                            <div className='GameCoinItemInner'>
                                {
                                    RemainJoin == 0 ?
                                        <div className='GameCoinRemainJoin'> Completed </div>
                                        :
                                        <div className='GameCoinRemainJoin'>
                                            <b className='GameCoinRemainJoinBold'>
                                             {RemainJoin > 0 ? abbreviate_number(RemainJoin) : 0} 
                                             {RemainJoin > 1 ? " Joins" : RemainJoin ==1 ? " Join" : null} 

                                             
                                            </b> to next level
                                        </div>
                                }
                            </div>

                        </div>
                        <div className='GameCoinItem'>
                            <Box sx={{}} className="GameCoinBoxProgress">
                                <LinearProgress variant="determinate" value={Progress}

                                    sx={{
                                        // backgroundColor: 'white',
                                        '& .MuiLinearProgress-bar': {
                                            // backgroundColor: '#006C44'
                                            // backgroundColor: Color
                                            backgroundColor: ForStyle.Progress
                                            // League=="Bronze"? '#BF8970' :
                                            // League=="Silver"? '#8E9FAF':
                                            // League=="Gold"? '#FFD100':
                                            // League=="Platinum"? '#ACB2AC':
                                            // League=="Dimond"? '#B9F2FF':
                                            // League=="Epic"? '#8C49CA':
                                            // League=="Legendary"? '#C00021':
                                            // League=="Master"? '#5656FF':
                                            // League=="Grandmaster"? '#0202D3':
                                            // League=="Lord"? '#006C44':
                                            // League=="Creator"? '#212121':null
                                            , borderRadius: '30px'
                                        }
                                    }} style={{ 'border': '1px solid #E0E0E0', 'height': '8px', 'backgroundColor': '#F5F5F5', 'color': '#BF8970 !important', 'borderRadius': '10px' }} />
                            </Box>
                        </div>
                        <div className='GameCoinItem'>
                            <div className='GameCoinStartAndEnd'>
                                {/* <div className='GameCoinStart'> {JoinCountRedux ? abbreviate_number(JoinCountRedux) : 0}  </div>
                                <span className='GameCoinStartBetween'>/</span>
                                <div className='GameCoinEnd'>   {abbreviate_number(LeagueLimitJoinProgress)} Join </div> */}
                            </div>

                        </div>
                    </>}


        </>
    )
}

export default InnerGame
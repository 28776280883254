import React from 'react'
import './ViewPackItem.css'
import ViewIcon from './../Public/Image/Home/ViewIcon.svg'
// import { GetView } from './../Api/GetView'
// import { GetCookie } from '../Action/GetCookie'
import { useEffect } from 'react'
import { setViewPackageData } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { setLoading } from './../app/Home'
import { AllApi } from './../Api/AllApi'
import xpplus from './../Public/Image/Join/xpplus.svg'

const ViewPackItemZarinPall = () => {

    const ViewPackageData = useSelector((state) => state.Home.ViewPackageData)
    const PostViewFilter = useSelector((state) => state.Home.PostViewFilter)
    const dispatch = useDispatch()
    const history = useHistory();

    const GetViewFunc = async () => {
        dispatch(setLoading(true))
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.GetView(true)
    
        if (res.code == 200) {

            dispatch(setViewPackageData(res.data.data))
            dispatch(setLoading(false))

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");

        } else {
            dispatch(setLoading(true))
        }

    }
    useEffect(() => {
        if (ViewPackageData.length === 0) {
            // GetViewFunc()
        }

    }, [])
    const CheckViewPackageItem = (price, count, sku, _id,countpost) => {
       
        history.push(`/GetUsernameView?price=${price}&count=${count}&ByCoin=false&PostViewFilter=${PostViewFilter}&sku=${sku}&_id=${_id}&countpost=${countpost}`);

    }
console.log(ViewPackageData)
    return (
        <>
            {
                ViewPackageData.length > 0 ?
                    ViewPackageData.map((ViewItem, i) =>
                        ViewItem.count_post == PostViewFilter ?
                            ViewItem.purchase.map((item, j) =>
                                <div className='ViewPackageItem' key={j} onClick={() => CheckViewPackageItem(item.price, item.view_count, item.sku, item._id,item.count_post)}>
                                    <div className='ViewItem'>
                                        <div>
                                            <img src={ViewIcon} />
                                        </div>
                                        <div className='ViewItemDigit'>
                                            <div className='ViewItemDigitNewPrice'>
                                            {item.view_count.toLocaleString()}
                                            {item.xpCount ?<span className='XpCountInlist'>+ {item.xpCount} <img src={xpplus} className='XpCountInlistIcon' /> </span>: null}  

                                                {/* {item.view_count_real.toLocaleString()} */}
                                            </div>
                                            {/* <div className='ViewItemDigitOldPrice'>
                                                {item.view_count.toLocaleString()}
                                                {item.view_count_real.toLocaleString()}

                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='ViewItem'>
                                        {item.discountString ? <div className='ViewItemOffDigit'>
                                            {item.discountString}
                                        </div>
                                            : null
                                        }

                                        <div >
                                            <div className='ViewItemOrder'>
                                                <button className='ViewItemOrderButton'> {parseInt(item.price).toLocaleString()} T</button>
                                            </div>
                                            {item.discountDecimal ?
                                                <div className='ViewItemOrderOldPrice'>
                                                  $  {parseFloat(item.price / (1 - item.discountDecimal/100)).toFixed(2) }

                                                  {/* $  {parseFloat((100 * item.price) / item.discountDecimal).toFixed(2)} */}
                                                </div>
                                                : null
                                            }

                                        </div>

                                    </div>
                                </div>
                            )

                            : null
                    )
                    : null
            }
        </>


    )
}

export default ViewPackItemZarinPall
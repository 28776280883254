import React from 'react'
import { AllApi } from '../Api/AllApi'
import { GameDB } from './../DataBase/GameDB'

import {
    SetAppSettingData,
    SetProfileData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward, SetxpCountLeague,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState
} from './../app/Game'
export class OnlineRequestProfileGame extends React.Component {

    constructor() {
        super();
        // this.dispatch = useDispatch()
    }
    async GetProfile() {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetProfile()

        if (Result.code == 200) {
            Data = Result
        } else if (Result.code == 999) {
            Data = 'Network'
        }

        return Data
    }
    async OnlineRequestProfile(dispatch) {


        let GetProfileRes = await this.GetProfile()

        if (GetProfileRes != 'Network') {
            // console.log(GetProfileRes)
            if (GetProfileRes) {
                let CashTime = GetProfileRes.data.cacheTime

                dispatch(SetJoinCountRedux(GetProfileRes.data.user.joinCount))
                dispatch(SetxpCountLeague(GetProfileRes.data.user.xpCountLeague))

                dispatch(SetProfileData(GetProfileRes.data))
                let LevelUser = false
                LevelUser = GetProfileRes.data.user.leagueLevel

                let NowInNow = new Date().getTime();
                let LastTime = CashTime * 1000 + NowInNow;
                GameDB.Profile.toArray(function (ProfileItem) {

                    if (ProfileItem.length > 0) {
                        GameDB.Profile.toCollection().modify(ProfileItem => {
                            ProfileItem.LastTime = LastTime;
                            ProfileItem.Profile = GetProfileRes.data.user

                        });
                    } else {
                        GameDB.Profile.add({ Profile: GetProfileRes.data.user, LastTime, LastUpdate: NowInNow });
                    }

                });
            }else{
                // console.log('first')
            }

        } else {
            // handleClick({ vertical: 'bottom', horizontal: 'center' })
            // OfflineRequestProfile()
            return GetProfileRes

        }
        return GetProfileRes
    }


}

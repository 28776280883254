import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import { useSelector, useDispatch } from 'react-redux'

import SuccessPayment from './../Public/Image/Home/SuccessPayment.svg'
import { setBottomSheetsuccessfulPayment } from './../app/Home'
import { useLocation } from 'react-router-dom';
import xpplus from './../Public/Image/Join/xpplus.svg'
import Person from './../Public/Image/Home/person_black_24dp 1.svg'
import View from './../Public/Image/Home/visibility_black_24dp (2).svg'

const SuccessfulPayment = () => {
    const dispatch = useDispatch()
    // const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)
    const BottomSheetsuccessfulPayment = useSelector((state) => state.Home.BottomSheetsuccessfulPayment)
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const Count = searchParams.get('count');
    const UserName = searchParams.get('username');
    const PaymentReturn = searchParams.get('payment');
    const PaymentReturnView = searchParams.get('paymentview');
    const Point = searchParams.get('point');
    const CountPost = searchParams.get('countPost');

    function onDismiss() {
        setTimeout(() => {
            dispatch(setBottomSheetsuccessfulPayment(false))
        }, 300);
    }
  

    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetsuccessfulPayment}>
            {/* <BottomSheet onDismiss={onDismiss} open={true}> */}

            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='successfulPaymentItem'>

                        <div className='UnsuccessfulPaymentValue'>
                            <img src={SuccessPayment} className='UnsuccessfulPaymentImg' />
                            Successful Order
                        </div>
                        <div className='SuccessfulPaymentText'>

                            {Count}
                            {/* {
                                PaymentReturn === 'true' ?
                                   <>
                                   <img src={Person} style={{ width: "21px", height: "14px" }} />
                                    Are sending to
                                   </> 
                                    :
                                    PaymentReturnView === 'true' ?
                                        <>
                                        <img style={{ width: "21px", height: "14px" }} src={View} />
                                        :
                                        CountPost == 1 ?
                                             have been registered for the single post at 
                                        :
                                        CountPost > 1 ?  have been registered for the last ${CountPost} posts at : null
                                        </>
                                        
                            } */}
                            <>
                                {PaymentReturn === "true" ? (
                                    <>
                                        <img src={Person} style={{ width: "21px", height: "14px" }} alt="Person" />
                                        Are sending to
                                    </>
                                ) : PaymentReturnView === "true" ? (
                                    <>
                                        <img src={View} style={{ width: "21px", height: "14px" }} alt="View" />
                                        {CountPost == '1'? "have been registered for the single post at "
                                            : 
                                            CountPost > 1
                                                ? `have been registered for the last ${CountPost} posts at `
                                                : null}
                                    </>
                                ) : null}
                            </>


                            <span style={{ "font-weight": "bold" }}>&nbsp;{UserName}</span>
                        </div>
                        {Point > 0 && Point != 'undefined' ? <div style={{ "marginTop": "4px", "marginBottom": "4px" }}>+</div> : null}
                        <div>
                            {Point > 0 && Point != 'undefined' ? <div className='pointInBottomSheet'>{Point} <img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px", "marginLeft": "4px" }} /></div> : null}
                        </div>
                    </div>

                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button className='BottomSheetShowPriceAndChannelButton' onClick={() => onDismiss()}>Got it</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default SuccessfulPayment
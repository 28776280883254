import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
// import Tooltip from '@mui/material/Tooltip';
import HeadImg from './../../src/Public/Image/Game/Star 4.svg'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { makeStyles } from '@mui/core/styles';
import Loading from './../Loading'


export default function LinearDeterminate() {
    const [progress, setProgress] = React.useState(0);
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const LevelUserHeaderProgress = useSelector((state) => state.Game.LevelUserHeaderProgress)

    useEffect(() => {

        if (LevelUserHeaderProgress) {
            let Limit = LevelUserHeaderProgress.user.xpLimit
            let Count = LevelUserHeaderProgress.user.xpCount


            let ProgressFinal = (Count * 100) / Limit

            setProgress(ProgressFinal)

        }


    }, [LevelUserHeaderProgress])

 
    function abbreviate_number(num) {
        if (num === null || num === undefined || num === false) return null;
        if (num === 0) return '0';
    
        let k = Math.floor((Math.log10(num)) / 3); // تعیین مقیاس (K, M, B, T)
        let scale = Math.pow(10, k * 3); // مقدار مقیاس برای تقسیم عدد
        let shortened = num / scale; // عدد را مقیاس‌بندی می‌کنیم
    
        return shortened.toFixed(1).replace(/\.0$/, '') + ['', 'K', 'M', 'B', 'T'][k]; // حذف `.0` از اعداد صحیح
    }
    
    return (
        LevelUserHeaderProgress.user ?
            <div className='ProgressBarInProfileComponent' style={{}}>
                <div className='YourLevel'>
                    Your Level:
                </div>
                <div className='HeaderLogo HeaderProgressLevelPts' style={{ "backgroundColor": "#fff", cursor: "default" }}>

                    <div className='HeaderLogoPtLevel' >

                        <div className='HeaderProgressBarImgContainer'>
                            <div className='HeaderProgressBarAbsoluteNumber'>
                                {LevelUserHeaderProgress.user.xpLevel}

                            </div>
                            <img src={HeadImg} className='HeaderProgressBarImg' style={{ width: "32px", height: "32px", paddingLeft: "3px" }} />
                        </div>

                    </div>
                    <div className='HeaderLogoPt'>
                        <div>

                            <span style={{ 'fontWeight': '700' }}>
                                {abbreviate_number(LevelUserHeaderProgress.user.xpLimit - LevelUserHeaderProgress.user.xpCount)}
                            </span>

                            {LevelUserHeaderProgress.user.xpLimit - LevelUserHeaderProgress.user.xpCount == 0 || LevelUserHeaderProgress.user.xpLimit - LevelUserHeaderProgress.user.xpCount == 1 ?
                                " Pt "
                                :
                                " Pts "
                            }
                               To level {LevelUserHeaderProgress.user.xpLevel ? LevelUserHeaderProgress.user.xpLevel + 1 : null}


                        </div>
                        {/* <div>
                         
                        </div> */}
                    </div>
                </div>
                <Box sx={{ width: '100%', height: '16px', marginLeft: '0px', 'rotate': '0deg !important', 'marginRight': '-3px', 'zIndex': '0' }}>




                    <LinearProgress variant="determinate" value={progress}
                        sx={{
                            backgroundColor: 'white',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: '#1976D2',borderRadius:'33px'
                            }
                        }} style={{ 'border': '1px solid #E0E0E0', 'height': '16px', 'backgroundColor': '#F5F5F5', 'color': '#BF8970 !important', "borderRadius": "10px", 'rotate': '180deg !important' }} />

                    <div className='PtsProfileBottom'>
                        {
                            LevelUserHeaderProgress.user.xpCount > 0 ? abbreviate_number(LevelUserHeaderProgress.user.xpCount) : 0
                        } 
                         {LevelUserHeaderProgress.user.xpCount == 0 || LevelUserHeaderProgress.user.xpCount == 1 ?
                                " Pt "
                                :
                                " Pts "
                            }
                    </div>

                </Box>
                {/* <div className='HeaderProgressBarImgContainer'>
                    <div className='HeaderProgressBarAbsoluteNumber'>
                        {LevelUserHeaderProgress.user.xpLevel}

                    </div>
                    <img src={HeadImg} className='HeaderProgressBarImg' />
                </div> */}
            </div>
            : <Loading />
    );
}

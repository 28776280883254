import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  Tab: ['Join', 'Buy', 'Sale', 'Transfer', 'Gift'],
  ActiveTab: 'Join',
  TelegramAccount: null,
  UserTelegramPasswordStatus: false,
  TelegramPassword: '',
  TelegramCode: null,
  ChannelTitle: null,
  ChannelUsername: null,
  ChannelTGID: null,
  ChannelImage: null,
  CountJoining: 0,
  AccountIsFilled: [],
  TooMuchLeft: [],
  AutoJoinLocal: [],
  ListForJoin: [],
  autoJoinAccounts: [], // ✅ Added autoJoinAccounts Array


}

export const JoinState = createSlice({
  name: 'JoinState',
  initialState,
  reducers: {
    SetActiveTab: (state, action) => {
      state.ActiveTab = action.payload
    },
    setTelegramAccount: (state, action) => {
      state.TelegramAccount = action.payload
    },
    SetUserTelegramPasswordStatus: (state, action) => {
      state.UserTelegramPasswordStatus = action.payload
    },
    SetTelegramPassword: (state, action) => {
      state.TelegramPassword = action.payload
    },
    SetTelegramCode: (state, action) => {
      state.TelegramCode = action.payload
    },
    SetChannelTitle: (state, action) => {
      state.ChannelTitle = action.payload
    },
    SetChannelUsername: (state, action) => {
      state.ChannelUsername = action.payload
    },
    SetChannelTGID: (state, action) => {
      state.ChannelTGID = action.payload
    },

    SetChannelImage: (state, action) => {
      state.ChannelImage = action.payload
    },
    SetCountJoining: (state, action) => {
      state.CountJoining = action.payload
    },
    SetAccountIsFilled: (state, action) => {
      state.AccountIsFilled = action.payload
    },
    SetTooMuchLeft: (state, action) => {
      state.TooMuchLeft = action.payload
    },
    setAutoJoinLocal: (state, action) => {
      // state.AutoJoinLocal.push(action.payload)
      state.AutoJoinLocal = action.payload
    },
    
    setListForJoin: (state, action) => {
      state.ListForJoin.push(action.payload)
    },
    // ✅ Add or Update an Account in autoJoinAccounts
    addOrUpdateAutoJoinAccount: (state, action) => {
      const { id, AutoJoin } = action.payload;
      const existingIndex = state.autoJoinAccounts.findIndex(acc => acc.id === id);

      if (existingIndex !== -1) {
        // Modify existing account
        state.autoJoinAccounts[existingIndex] = { id, AutoJoin };
      } else {
        // Add new account
        state.autoJoinAccounts.push({ id, AutoJoin });
      }
    },

    // ✅ Remove an account from autoJoinAccounts
    removeAutoJoinAccount: (state, action) => {
      const idToRemove = action.payload;
      state.autoJoinAccounts = state.autoJoinAccounts.filter(acc => acc.id !== idToRemove);
    },

    // ✅ Clear all autoJoin accounts
    clearAutoJoinAccounts: (state) => {
      state.autoJoinAccounts = [];
    }


  },
})

// Action creators are generated for each case reducer function
export const { SetActiveTab, setTelegramAccount, SetChannelTGID
  , SetUserTelegramPasswordStatus, SetTelegramPassword, setListForJoin,
  SetTelegramCode, SetChannelTitle, SetChannelUsername,
  SetChannelImage, SetCountJoining, SetAccountIsFilled, SetTooMuchLeft, 
  setAutoJoinLocal, addOrUpdateAutoJoinAccount, removeAutoJoinAccount, clearAutoJoinAccounts 
 } = JoinState.actions
export default JoinState.reducer
import React from 'react'
import Coin from './../Public/Image/Join/Coin.svg'
import CoinBig from './../Public/Image/Home/Coin.svg'

import { useEffect } from 'react'
import './CoinHeader.css'
import { useSelector, useDispatch } from 'react-redux'
// import { GetProfile } from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from '../Action/GetCookie'
import { setUserCoin } from './../app/Home'
import { SetEmail } from './../app/Slice'
import { db } from './../DataBase/db'
import { useHistory } from 'react-router-dom';
import { TelegramDB } from './../DataBase/TelegramDB'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Link } from 'react-router-dom';
import { SetActiveTab } from './../app/JoinState'
import { setLoading } from './../app/Home'
import xpplus from './../Public/Image/Join/xpplus.svg'
import { useState } from 'react'
import HeadImg from './../../src/Public/Image/Game/Group 26086606.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { GameDB } from '../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';
import Star from './../Public/Image/Game/Star.svg'
import StarFull from './../Public/Image/Join/Star 8 (1).svg'

const CoinHeader = () => {
    const dispatch = useDispatch()
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const history = useHistory();

    const UserCoin = useSelector((state) => state.Home.UserCoin)
    const Token = GetCookie('Token');
    const [PointDialogState, setPointDialogState] = useState('')
    const [xpCount, setxpCount] = useState('')
    const [xpLevel, setxpLevel] = useState('')
    const [xpLimit, setxpLimit] = useState('')
    const xpCountLeague = useSelector((state) => state.Game.xpCountLeague)

    const GetProfileFunction = async () => {
        const MakeApiClass = new AllApi();

        let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()

        if (TelegramAccountUpdatelimit.length > 0) {
            let arr = []
            TelegramAccountUpdatelimit.map(item => {
                arr.push(item.BotSession)
            })

            let restransfer = await MakeApiClass.accountTransfer(arr)

            arr = []
        } else {
            let arr = []


            let restransfer = await MakeApiClass.accountTransfer(arr)

        }






        let AppSetting = await GameDB.AppSetting.toArray()

        setPointDialogState(AppSetting[0].AppSetting.PointDialog)

        let res = await MakeApiClass.GetProfile(Token)
        // let res = await GetProfile(Token)


        if (res.code == 200) {

            setxpCount(res.data.user.xpCount)
            setxpLevel(res.data.user.xpLevel)
            setxpLimit(res.data.user.xpLimit)
            dispatch(setUserCoin(res.data.user.coins))
            let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })
            dispatch(SetEmail(res.data.user.email))

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        } else if (res.code == 999) {

            handleClick({ vertical: 'bottom', horizontal: 'center' })

        }

    }
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };

    useEffect(() => {
        GetProfileFunction()
    }, [UserCoin])
    const urlParams = new URLSearchParams(window.location.search);


    // const Tab = useSelector((state) => state.JoinState.Tab)
    // const ActiveTab = useSelector((state) => state.JoinState.ActiveTab)
    const SetTabsStatusJoin = (index) => {
        dispatch(setLoading(true))
        localStorage.setItem("CoinFilter", index)
        dispatch(SetActiveTab(index))
        let name
        if (index == 0) {
            name = "Join"
        } else if (index == 1) {
            name = 'Buy'
        } else if (index == 2) {
            name = 'Sale'
        } else if (index == 3) {
            name = "Transfer"
        } else if (index == 4) {
            name = "Gift"
        }

        urlParams.set('Status', index);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        setTimeout(() => {
            dispatch(setLoading(false))
        }, 100000);

    }




    const OpenBottomSheetShowXpDetails = () => {
        setBottomSheetState(true)
    }
    const [BottomSheetState, setBottomSheetState] = useState(false)
    function onDismiss() {
        setTimeout(() => {
            setBottomSheetState(false)
        }, 300);

    }

    function abbreviate_number(num) {
        if (num === null || num === undefined || num === false) return null;
        if (num === 0) return '0';

        let k = Math.floor((Math.log10(num)) / 3); // تعیین مقیاس (K, M, B, T)
        let scale = Math.pow(10, k * 3); // مقدار مقیاس برای تقسیم عدد
        let shortened = num / scale; // عدد را مقیاس‌بندی می‌کنیم

        return shortened.toFixed(1).replace(/\.0$/, '') + ['', 'K', 'M', 'B', 'T'][k]; // حذف `.0` از اعداد صحیح
    }


    return (

        <div className='CoinHeader'>
            <div className='HeaderLogo' onClick={() => OpenBottomSheetShowXpDetails()}>
                {/* <img src={Membersgram} className='HeaderLogoImg' /> */}
                {/* <div className='HeaderLogoPtLevel' >

          <div className='HeaderProgressBarImgContainer'> */}
                {/* <div className='HeaderProgressBarAbsoluteNumber' > */}
                {/* {xpLevel} */}
                {/* {xpCount} */}

                {/* </div> */}
                {/* <img src={Star} className='HeaderProgressBarImg' /> */}
                {/* </div>

        </div> */}
                <div className='HeaderLogoPt'>
                    {/* <div> */}
                    <img src={StarFull} className='HeaderProgressBarImg' />

                    <span style={{ 'fontWeight': '700', fontSize: "14px", }}>
                        {/* {abbreviate_number(xpLimit - xpCount)} */}
                        {xpCountLeague > 0 ? abbreviate_number(xpCountLeague) : 0}
                    </span>
                    {/* Pts */}


                    {/* </div> */}
                    {/* <div> */}
                    {/* To level {xpLevel ? xpLevel + 1 : null} */}
                    {/* </div> */}
                </div>
            </div>
            {/* <div  className='HeaderCoin' onClick={() => SetTabsStatusJoin('Join')}> */}
            <div className='HeaderCoin' >
                {/* <AddCircleRoundedIcon className='AddCoinIcon' /> */}

                <div>
                    {UserCoin.toLocaleString()}
                </div>
                <div className='HeaderCoinImgContainer'>
                    {
                        CountJoining > 0 ?
                            <>
                                <div className='CoinHeaderImageLoader'></div>
                                <img src={Coin} className='HeaderCoinImg1' />
                            </>

                            :
                            <img src={Coin} className='HeaderCoinImg1' />

                    }

                </div>

            </div>

            <BottomSheet onDismiss={onDismiss} open={BottomSheetState}>
                <div className='RegisterBeforeBottomSheet'>
                    <div className='RegisterBeforeBottomSheetHeader'>
                        <div className='HeaderLogoBottomSheet' >
                            {xpCountLeague == 0 || xpCountLeague == 1 ?
                                "Point"
                                :
                                "Points"
                            }  in this event:
                            <b style={{ margin: "0 4px 0 4px" }}>
                                {xpCountLeague > 0 ? xpCountLeague.toLocaleString() : 0}
                            </b>
                            <img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px" }} />

                        </div>

                    </div>
                    <div className='HeaderLogoBottomSheetText' >
                        How can we get Points?

                    </div>
                    <ul className='BottomSheetUlXpUl'>
                        {
                            PointDialogState ?
                                PointDialogState.map((item, i) =>
                                    <li key={i} style={{ "marginTop": "8px" }}>{item}</li>
                                )
                                : null
                        }

                    </ul>

                    <div className='RegisterBeforeBottomSheetFooter'>
                        <Link to="/Profile" className='BottomSheetCancelButton'  >Show All pts</Link>
                        <button className="BottomSheetLoginButton" onClick={() => setBottomSheetState(false)}>Got it</button>

                    </div>
                </div>
            </BottomSheet>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                message="Something went wrong"
                key={vertical + horizontal}
                autoHideDuration={3000}
            />
        </div>
    )
}

export default CoinHeader
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  AppSettingData: [],
  UserLeagueData: [],
  ProfileData: [],
  TopJoinerData: [],
  PrevLeagueData: [],
  GameLoading: false,
  // new design

  CongratulationsBottomSheet: false,
  CongratulationsBottomSheet2: false,
  CongratulationsBottomSheet3: false,
  PromotionBottomSheet: false,
  GetNameBottomSheet: false,


  MyLeagueRank: 'Bronze III',
  MyLeagueRankNumber: 1,
  LeagueLimitJoinProgress: 0,
  LeagueMinimumJoinProgress: 0,
  RewardLeague: '',
  GiftTopjoin: [],
  NameAccount: false,
  JoinCountRedux: -1,
  UserReward: '',
  ClaimRewardCall: false,
  DataClaimReward: false,
  ChangeLeagueAndReqApi: false,
  LeagueEnd: false,
  SnackBarEndLeagueState: false,
  XpCount: -1,
  XpLimit: 0,
  LevelUserHeaderProgress: 0,
  TopJoinerTime: {},
  IsBetweenLeaguge: null,
  joinLeagueEnable: true,
  AddXpFirstregister: 0,
  AddCoinFirstregister: 0,
  joinCoinRewards: 0,
  joinXpRewards: 0,
  leagueWithXp: null,
  XpMax: 0,
  XpMin: 0,
  xpCountLeague: -1,
  verifyBotEnable:""
}
export const Game = createSlice({
  name: 'Game',
  initialState,
  reducers: {
    SetCongratulationsBottomSheet: (state, action) => {
      state.CongratulationsBottomSheet = action.payload
    },
    SetCongratulationsBottomSheet2: (state, action) => {
      state.CongratulationsBottomSheet2 = action.payload
    },
    SetCongratulationsBottomSheet3: (state, action) => {
      state.CongratulationsBottomSheet3 = action.payload
    },
    SetPromotionBottomSheet: (state, action) => {
      state.PromotionBottomSheet = action.payload
    },
    SetGetNameBottomSheet: (state, action) => {
      state.GetNameBottomSheet = action.payload
    },
    SetAppSettingData: (state, action) => {
      state.AppSettingData = action.payload
    },
    SetUserLeagueData: (state, action) => {
      state.UserLeagueData = action.payload
    },
    SetProfileData: (state, action) => {
      state.ProfileData = action.payload
    },
    SetTopJoinerData: (state, action) => {

      state.TopJoinerData = action.payload
    },
    SetPrevLeagueData: (state, action) => {

      state.PrevLeagueData = action.payload
    },

    SetMyLeagueRank: (state, action) => {
      state.MyLeagueRank = action.payload
    },
    SetLeagueLimitJoinProgress: (state, action) => {
      state.LeagueLimitJoinProgress = action.payload
    },
    SetLeagueMinimumJoinProgress: (state, action) => {
      state.LeagueMinimumJoinProgress = action.payload
    },

    SetRewardLeague: (state, action) => {
      state.RewardLeague = action.payload
    },
    SetMyLeagueRankNumber: (state, action) => {
      state.MyLeagueRankNumber = action.payload
    },
    SetGiftTopjoin: (state, action) => {
      state.GiftTopjoin = action.payload
    },
    SetNameAccount: (state, action) => {
      state.NameAccount = action.payload
    },
    SetJoinCountRedux: (state, action) => {
      state.JoinCountRedux = action.payload
    },

    SetUserReward: (state, action) => {
      state.UserReward = action.payload
    },
    SetClaimRewardCall: (state, action) => {
      state.ClaimRewardCall = action.payload
    },

    SetDataClaimReward: (state, action) => {
      state.DataClaimReward = action.payload
    },
    SetChangeLeagueAndReqApi: (state, action) => {
      state.ChangeLeagueAndReqApi = action.payload
    },

    SetLeagueEnd: (state, action) => {
      state.LeagueEnd = action.payload
    },
    SetSnackBarEndLeagueState: (state, action) => {
      state.SnackBarEndLeagueState = action.payload
    },

    SetXpCount: (state, action) => {
      state.XpCount = action.payload
    },
    SetXpLimit: (state, action) => {
      state.XpLimit = action.payload
    },
    SetLevelUserHeaderProgress: (state, action) => {
      state.LevelUserHeaderProgress = action.payload
    },
    SetTopJoinerTime: (state, action) => {
      state.TopJoinerTime = action.payload
    },
    SetIsBetweenLeaguge: (state, action) => {
      state.IsBetweenLeaguge = action.payload
    },
    SetGameLoading: (state, action) => {
      state.GameLoading = action.payload
    },
    SetjoinLeagueEnable: (state, action) => {
      state.joinLeagueEnable = action.payload
    },
    SetAddXpFirstregister: (state, action) => {
      state.AddXpFirstregister = action.payload
    },
    SetAddCoinFirstregister: (state, action) => {
      state.AddCoinFirstregister = action.payload
    },


    SetjoinCoinRewards: (state, action) => {
      state.joinCoinRewards = action.payload
    },
    SetjoinXpRewards: (state, action) => {
      state.joinXpRewards = action.payload
    },

    SetleagueWithXp: (state, action) => {
      state.leagueWithXp = action.payload
    },

    SetXpMax: (state, action) => {
      state.XpMax = action.payload
    },
    SetXpMin: (state, action) => {
      state.XpMin = action.payload
    },
    SetxpCountLeague: (state, action) => {
      state.xpCountLeague = action.payload
    },
    SetverifyBotEnable: (state, action) => {
      state.verifyBotEnable = action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  SetjoinXpRewards, SetxpCountLeague,SetverifyBotEnable,
  SetleagueWithXp,
  SetXpMax, SetXpMin,
  SetjoinCoinRewards,
  SetGameLoading,
  SetCongratulationsBottomSheet,
  SetjoinLeagueEnable,
  SetAddCoinFirstregister,
  SetAddXpFirstregister,
  SetTopJoinerTime,
  SetIsBetweenLeaguge,
  SetProfileData,
  SetTopJoinerData,
  SetPrevLeagueData,
  SetCongratulationsBottomSheet2,
  SetCongratulationsBottomSheet3,
  SetPromotionBottomSheet,
  SetGetNameBottomSheet,
  SetAppSettingData,
  SetUserLeagueData,
  SetMyLeagueRank,
  SetLeagueLimitJoinProgress,
  SetLeagueMinimumJoinProgress,
  SetRewardLeague,
  SetMyLeagueRankNumber,
  SetGiftTopjoin,
  SetNameAccount,
  SetJoinCountRedux,
  SetUserReward,
  SetClaimRewardCall,
  SetDataClaimReward,
  SetLevelUserHeaderProgress,
  SetChangeLeagueAndReqApi,
  SetLeagueEnd,
  SetSnackBarEndLeagueState,
  SetXpCount,
  SetXpLimit
} = Game.actions
export default Game.reducer
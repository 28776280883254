import React, { useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Logo1 from './../Public/Image/Logo.svg'
import Logo2 from './../Public/Image/Logo1.svg'
import Logo3 from './../Public/Image/Logo2.svg'
import Logo4 from './../Public/Image/Logo3.svg'
import Animation1 from './../Public/Image/Auth/1.json'
import Animation2 from './../Public/Image/Auth/2.json'
import Animation3 from './../Public/Image/Auth/3.json'
import Lottie from "lottie-react";
const slides = [
    {
        image: Animation1,
        header: 'Welcome to Membersgram',
        des: 'Increase your Channels & Groups Members and Views with filters'
    },
    {
        image: Animation2,
        header: 'Join & Get FREE Coins',
        des: 'Add Telegram account, join channels and groups, earn thousands of FREE coins'
    },
    {
        image: Animation3,
        header: 'Are you Competitive?',
        des: 'Join and earn rewards by participating in the Game'
    },
    // {
    //     image: Logo4,
    //      header: 'Get coins', 
    //      des: 'By adding any Telegram account, you can receive free coins'
    // }
];

export default function Carousel() {
    // const handleTouchMove = (e) => {
    //     setTouchEndX(e.touches[0].clientX);
    //     const newOffsetX = e.touches[0].clientX - startX;
    //     setOffsetX(newOffsetX);
    // };
    const [offsetX, setOffsetX] = useState(0);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const sliderRef = useRef(null);

    const goNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const goPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };

    // Handle touch/mouse start
    const handleStart = (clientX) => {
        setStartX(clientX);
        setIsDragging(true);
    };

    // Handle touch/mouse move
    const handleMove = (clientX) => {
        if (!isDragging) return;
        const diff = clientX - startX;

        if (diff > 50) {
            goPrev();
            setIsDragging(false);
        } else if (diff < -50) {
            goNext();
            setIsDragging(false);
        }
    };

    // Handle touch/mouse end
    const handleEnd = () => {
        setIsDragging(false);
    };

    return (
        //    <div className="CarouselBox"> 
        <Box  sx={{ position: "relative", width: "100%", margin: " 0 auto", overflow: "hidden", userSelect: "none" }}>
            {/* Image Wrapper with Touch & Mouse Events */}
            <Box
                ref={sliderRef}
                sx={{
                    display: "flex",
                    transition: "transform 0.5s ease-in-out",
                    transform: `translateX(-${currentIndex * 100}%)`,
                }}
                onTouchStart={(e) => handleStart(e.touches[0].clientX)}
                onTouchMove={(e) => handleMove(e.touches[0].clientX)}
                onTouchEnd={handleEnd}
                onMouseDown={(e) => handleStart(e.clientX)}
                onMouseMove={(e) => isDragging && handleMove(e.clientX)}
                onMouseUp={handleEnd}
                onMouseLeave={handleEnd}
                style={{ cursor: isDragging ? "grabbing" : "grab" }}
            >
                {slides.map((slide, index) => (
                    <Box key={index} sx={{ minWidth: "100%" }}>
                        {/* <img src={slide.image} alt={`Slide ${index}`} style={{ width: "100%", borderRadius: "10px", pointerEvents: "none" }} /> */}

                        <Lottie id="ImageViewer"
                            loop={true}
                            className='slidable-element'
                            animationData={slide.image}
                            style={{ "width": "300px", "height": "300px", "margin": "auto", "marginTop": "72px", "transform": `translateX(${offsetX}px)` }} />

                    </Box>
                ))}
            </Box>

            {/* Text Between Image and Dots */}
            <div className="IntroHeader">
                {slides[currentIndex].header}
            </div>
            <div className="IntroDescription">
                {slides[currentIndex].des}
            </div>
            {/* <Typography variant="h5" sx={{ textAlign: "center", mt: 2, fontWeight: "bold", color: "#333" }}>
                {slides[currentIndex].header}
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center", mt: 2, fontWeight: "bold", color: "#333" }}>
                {slides[currentIndex].des}
            </Typography> */}
            {/* Pagination Dots */}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3, height: "48px", alignItems: "center" }}>
                {slides.map((_, index) => (
                    <Box
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        sx={{
                            width: 8,
                            height: 8,
                            borderRadius: "50%",
                            backgroundColor: index === currentIndex ? "#1976D2" : "#BDBDBD",
                            margin: "0 5px",
                            cursor: "pointer",
                        }}
                    />
                ))}
            </Box>
            <div className='IntroButtons'>
                <button className='IntroSkipButton'>
                    Skip
                </button>
                <button className='IntroNextButton' onClick={goNext}>
                    Next
                </button>
            </div>
            {/* Previous Button */}
            {/* <Button onClick={goPrev} sx={{ position: "absolute", top: "50%", left: 10, transform: "translateY(-50%)", background: "rgba(0,0,0,0.5)", color: "#fff" }}> */}
            {/* <ArrowBackIos /> */}
            {/* </Button> */}

            {/* Next Button */}
            {/* <Button onClick={goNext} sx={{ position: "absolute", top: "50%", right: 10, transform: "translateY(-50%)", background: "rgba(0,0,0,0.5)", color: "#fff" }}> */}
            {/* <ArrowForwardIos /> */}
            {/* </Button> */}
        </Box>
        // </div>
    );
}

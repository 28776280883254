import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './AccountList.css'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { API_ID, API_HASH } from './../Const'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import LimitOnProfile from './../Public/Image/Join/LimitOnProfile.svg'
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton';
import { useEffect } from 'react'
import { TelegramDB } from './../DataBase/TelegramDB'
import { useState } from 'react'
import Loading from './../Loading'
// import { AutoJoinClass } from './AutoJoinClass'
import { SetCountJoining } from './../app/JoinState'
import { BottomSheet } from 'react-spring-bottom-sheet'
import ReactCountryFlag from "react-country-flag"
import Snackbar from '@mui/material/Snackbar';
import { setMiniLoading } from './../app/Home'
import { setUserCoin } from './../app/Home'
import WifiOffIcon from '@mui/icons-material/WifiOff';
// import { JoinChannel } from './../CoinApi/JoinChannel'

import { GetCookie } from './../Action/GetCookie'
// import { GetChannels } from './../CoinApi/GetChannels'
import { CheckConnection } from './CheckConnection'
import { SetAccountIsFilled } from './../app/JoinState'
import { SetTooMuchLeft } from './../app/JoinState'
// import { setAutoJoinLocal } from './../app/JoinState'
import { addOrUpdateAutoJoinAccount } from "./../app/JoinState";

import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';
import { SetJoinCountRedux } from './../app/Game'
import { setListForJoin } from './../app/JoinState'

import AutoJoinDisableDark from './../Public/Image/Join/AutoJoinDisableDark.svg'
import GameCoin from './GameCoin/GameCoin'
import AddAccount2 from './../Public/Image/Join/AddAccount2.svg'
import AutoJoinList from './../Public/Image/Join/AutoJoinList.svg'


import xpplus from './../Public/Image/Join/xpplus.svg'
import coinplus from './../Public/Image/Join/coinplus.svg'
import Animation from './../Public/Image/Join/1.json'
import newuser from './../Public/Animation/newuser.json'
// import newuser from './../Public/Animation/lovelydog_naked 2.json'
import Lottie from "lottie-react";
import JoinStop from './../Public/Image/Join/JoinStop.svg'
import {
    SetXpCount, SetxpCountLeague
} from './../app/Game'
import { CheckDataBaseJoin } from '../JoinAction/CheckDataBaseJoin'

const AccountList = ({ TelegramAccount }) => {



    const history = useHistory();

    let Token = GetCookie('Token');
    const dispatch = useDispatch()
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const AccountIsFilled = useSelector((state) => state.JoinState.AccountIsFilled)
    const TooMuchLeft = useSelector((state) => state.JoinState.TooMuchLeft)
    const ListForJoin = useSelector((state) => state.JoinState.ListForJoin)
    const [LoadingState, setLoadingState] = useState(false)
    const [ShowLogOut, setShowLogOut] = useState(false)
    const [NewUser, setNewUser] = useState(false)
    const [SnackbarLogout, setSnackbarLogout] = useState(false);
    const [PhoneForLogout, setPhoneForLogout] = useState('')
    const [IdForLogout, setIdForLogout] = useState('')
    const [LoadingForLogOut, setLoadingForLogOut] = useState(false)
    const [AccountCount, setAccountCount] = useState(0)
    const [FloodWaitState, setFloodWaitState] = useState(false)
    const [ShowStartAll, setShowStartAll] = useState(true)
    // const [TelegramAccount, setTelegramAccount] = useState('')
    const AutoJoinLocal = useSelector((state) => state.JoinState.AutoJoinLocal)
    const joinLeagueEnable = useSelector((state) => state.Game.joinLeagueEnable)
    const AddXpFirstregister = useSelector((state) => state.Game.AddXpFirstregister)
    const AddCoinFirstregister = useSelector((state) => state.Game.AddCoinFirstregister)
    const joinXpRewards = useSelector((state) => state.Game.joinXpRewards)
    const joinCoinRewards = useSelector((state) => state.Game.joinCoinRewards)
    const leagueWithXp = useSelector((state) => state.Game.leagueWithXp)
    // const autoJoinAccounts = useSelector(state => state.JoinState.autoJoinAccounts);

    // console.log(autoJoinAccounts);
    // useEffect(() => {
    //     // console.log(joinLeagueEnable,leagueWithXp)  
    //     console.log("useeffect",autoJoinAccounts)

    // }, [leagueWithXp,autoJoinAccounts])
    const autoJoinAccounts = useSelector(state => state.JoinState.autoJoinAccounts);
    useEffect(() => {
       CheckJoinFunction(dispatch)
     }, [])
   
     const CheckJoinFunction = async (dispatch) => {
       const CheckDataBaseJoinClass = new CheckDataBaseJoin();
       let TelegramDbRes = await CheckDataBaseJoinClass.CheckAccounts(dispatch); 
     }
    const CheckAccounts = async () => {
        let ActiveAccount = [];
        let arr = [];
        let RestrictAccount = [];

        let res = await GetUpdatedTelegramAccont();

        // فیلتر کردن حساب‌های فعال
        ActiveAccount = res.filter(item => item.Session !== false && item.BotSession !== "");

        // پردازش حساب‌هایی که AutoJoin دارند
        res.forEach(item => {
            if (item.AutoJoin === true && item.Session !== false && item.BotSession !== false) {
                // console.log('auto join start ')
                setAutoJoinStatus(true);

                if (item.FloodWait > 0 || item.CHANNELS_TOO_MUCH === true || item.TooMuchLeft === true) {
                    RestrictAccount.push(item.id);
                } else {
                    // setAutoJoinStatus(true);
                    arr.push(item.id);
                }
            }
        });

        // بروزرسانی وضعیت‌ها
        setAccountCount(ActiveAccount.length);
        dispatch(SetCountJoining(arr.length));

        setShowStartAll(RestrictAccount.length !== res.length);

        // مقداردهی `ListForJoin` در `ActiveAccount`
        ActiveAccount.forEach(item => {
            item.ListForJoin = [];
        });

        // ارسال حساب‌ها به API
        const MakeApiClass = new AllApi();
        await MakeApiClass.SessionsAccountPut(ActiveAccount);
    };
    const [Banny, setBanny] = useState()
    const CountBannedLogOut = async () => {

        let arr = []
        let res = await GetUpdatedTelegramAccont()

        let testarr = [1]
        localStorage.setItem('Banned', JSON.stringify(testarr))


        if (res.length > 0) {

            res.map((item, i) => {

                if (item.Session == false) {
                    let BannedFromLocal = JSON.parse(localStorage.getItem('Banned'))
                    // console.log(BannedFromLocal)
                    if (BannedFromLocal != null || BannedFromLocal != 'null') {
                        if (BannedFromLocal.length > 0) {
                            BannedFromLocal.map(banned => {
                                if (banned.id != item.id) {

                                    arr.push(item)

                                }
                            })
                        } else {
                            arr.push(item)
                        }
                    } else {
                        arr.push(item)
                    }
                }
            }
            )
        }

        let uniqueArray = arr.filter(function (item, pos) {
            return arr.indexOf(item) == pos;
        })


        localStorage.setItem('Banned', JSON.stringify(uniqueArray))


        setBanny(uniqueArray)
        CheckAccounts()
    }
    const newUserStorage = localStorage.getItem("NewUser")

    function onDismissNewUser() {
        setTimeout(() => {
            setNewUser(false)
        }, 300);
    }
    useEffect(() => {

        if (newUserStorage == 'true') {
            setNewUser(true)
            localStorage.setItem("NewUser", false)
        }
        CheckAccounts()

        setCountJoiningLoading(true)
        setTimeout(() => {
            setCountJoiningLoading(false)
        }, 2000);
        CheckAccounts()
        // }, [CountJoining])
    }, [])

    const GetUpdatedTelegramAccont = async () => {
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        return TelegramAccountUpdate
    }
    const CheckStatus = async (item) => {
        if (item.Session && item.BotSession) {
            let ResConnection

            const delayTime = (time) =>
                setTimeout(() => {
                    // console.log("run after:", time);
                    try {
                        if (ResConnection.status == true && ResConnection.Client != false) {

                        } else {
                            // handleClick({ vertical: 'bottom', horizontal: 'center' })
                            dispatch(setMiniLoading(false))
                        }
                    } catch (error) {
                        // clearTimeout(delayTime);

                        // handleClick({ vertical: 'bottom', horizontal: 'center' })
                        dispatch(setMiniLoading(false))
                    }

                    CountBannedLogOut()
                }, time);

            const runTime = async () => {
                await delayTime(6000);
            };

            runTime();
            // console.log(item.Session)
            // ResConnection = await CheckConnection(item.Session, item.id)
        }

    }
    const [CountJoiningLoading, setCountJoiningLoading] = useState(false)

    useEffect(() => {
        let isMounted = true; // Prevent updates if the component unmounts
        // setGetAccounts(autoJoinAccounts);
        // console.log(autoJoinAccounts)
        localStorage.setItem("autoJoinAccounts", JSON.stringify(autoJoinAccounts) )
        
        const fetchAccounts = async () => {
            let UpdatedAccount = await GetUpdatedTelegramAccont();

            if (isMounted && UpdatedAccount) {
                CountBannedLogOut();
                CheckAccounts();

                UpdatedAccount.forEach(item => { 
                    if (item.Session !== false) {
                        CheckStatus(item);

                        if (item.AutoJoinSingle) {
                            if (!AutoJoinStatus) {
                                // console.log(item.AutoJoinSingle, AutoJoinStatus);
                                autoJoinAccounts.map(Ritem => {
                                    if (Ritem.id == item.id) {
                                        if (Ritem.AutoJoin == false) {
                                            // console.log('run taki') 
                                            dispatch(addOrUpdateAutoJoinAccount({ id: item.id, AutoJoin: true }));
                                            CheckFloodWait(item, autoJoinAccounts);
                                            // StartOneAccountJoin(item);
                                        }
                                    }
                                })
                            }
                        } else if (item.AutoJoin) {
                            // console.log(autoJoinAccounts)
                            CheckFloodWait(item, autoJoinAccounts);

                        } else {
                            // console.log(autoJoinAccounts)

                            CheckFloodWait(item, autoJoinAccounts); 
                        }
                    }
                });
            }
        };

        fetchAccounts(); // Initial fetch

        const interval = setInterval(() => {
            fetchAccounts();
        }, 1000);

        return () => {
            clearInterval(interval); // Cleanup interval
            isMounted = false;
        };
    }, [autoJoinAccounts]);




    const [AutoJoinStatus, setAutoJoinStatus] = useState(false)
    async function SetAutoJoinDB(ID) {
        try {
            if (!ID || isNaN(ID)) {
                throw new Error("Invalid ID provided");
            }

            const updatedCount = await TelegramDB.Data
                .where({ id: Number(ID) })
                .modify({ AutoJoin: true });

            if (updatedCount === 0) {
                // console.warn(`No records updated for ID: ${ID}`);
            } else {
                // console.log(`AutoJoin set to true for ID: ${ID}`);
            }
        } catch (error) {
            // console.error(`Failed to update AutoJoin for ID ${ID}:`, error);
        }
    }

    const [AnimationJoin, setAnimationJoin] = useState([]); // Array to store child elements

    const AutoManualJoinObject = {
        // async connectWithTimeout() {
        //     try {
        //         const stringSession = new StringSession(this.MySession);
        //         const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
        //             connectionRetries: 1,
        //         });

        //         const connectPromise = Client.connect().then(async () => {
        //             // صبر کنیم تا مقداردهی تکمیل بشه
        //             await new Promise((resolve) => setTimeout(resolve, 1000));

        //             // بررسی کنیم که مقداردهی شده باشد
        //             if (!Client.session || !Client.session.save()) {
        //                 throw new Error("Invalid session data");
        //             }

        //             // بررسی کنیم که کاربر لاگین کرده باشد
        //             const isAuthorized = await Client.isUserAuthorized();
        //             if (!isAuthorized) throw new Error("User is not authorized (Invalid Session)");

        //             return Client; // اتصال موفق
        //         });

        //         const timeoutPromise = new Promise((_, reject) => {
        //             setTimeout(() => reject(new Error('Connection timed out after 5 seconds')), 5000);
        //         });

        //         return await Promise.race([connectPromise, timeoutPromise]);
        //     } catch (error) {
        //         console.error('Connection failed:', error);
        //         return null; // در صورت شکست، مقدار null برمی‌گردد
        //     }
        // }
        // ,

        // async connectWithTimeout() {
        //     const stringSession = new StringSession(this.MySession);
        //     const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
        //         connectionRetries: 1,
        //     });
        //     const connectPromise = Client.connect();
        //     const timeoutPromise = new Promise((resolve, reject) => {
        //         setTimeout(() => {
        //             reject(('Connection timed out after 5 seconds'));
        //         }, 5000);
        //     });

        //     return Promise.race([connectPromise, timeoutPromise, Client]);
        // },
        // async checkConnection() {
        //     try {
        //         const connectRes = await this.connectWithTimeout();

        //         if (connectRes && await connectRes.isUserAuthorized()) {
        //             return { status: true, Client: connectRes };
        //         } else {
        //             return { status: false };
        //         }
        //     } catch (error) {
        //         console.error('Connection check failed:', error);
        //         return { status: false };
        //     }
        // },
        // async checkConnection() {
        //     try {
        //         const connectRes = await this.connectWithTimeout(this.MySession, this.ID);
        //         // console.log('Connection successful:', connectRes);
        //         return { status: true, Client: connectRes }
        //     } catch (error) {
        //         // console.log('Connection failed:', error);

        //         return { status: false }
        //     }
        // },
        async Join(Client, List, MySession, ID) {

            let ResConnection1
            let JoinTimeout;

            let JoinRes

            try {
                let ConTimeout;
                const myPromise1 = new Promise((resolve, reject) => {
                    resolve(Client.invoke(
                        new Api.channels.JoinChannel({
                            channel: List[0].username,
                        })
                    ));
                    clearTimeout(ConTimeout)
                });


                const myPromise2 = new Promise((resolve, reject) => {

                    ConTimeout = setTimeout(resolve, 20000, false)
                });
                JoinRes = await Promise.race([myPromise1, myPromise2])
            } catch (error) {
                if (error.errorMessage == 'FLOOD') {
                    let LimitTime = error.seconds * 1000
                    let FinalLimit = Date.now() + LimitTime

                    try {
                        let res = await TelegramDB.Data.where({ id: Number(this.ID) }).modify({ FloodWait: FinalLimit })
                    } catch (error) {
                        // console.log(error)
                    }

                    JoinRes = 'Flood'
                } else {
                    JoinRes = error
                }

            }


            return JoinRes
        },
        async IsMember(Client, List, ID, User) {

            let JoinTimeout;


            let IsMemberUser;

            try {

                let ConTimeout;
                const myPromise1 = new Promise((resolve, reject) => {
                    resolve(Client.invoke(
                        new Api.channels.GetParticipant({
                            channel: List[0].username,
                            participant: User.id.toString(),
                        })
                    ));
                    clearTimeout(ConTimeout)
                });
                const myPromise2 = new Promise((resolve, reject) => {

                    ConTimeout = setTimeout(resolve, 20000, false)
                });
                IsMemberUser = await Promise.race([myPromise1, myPromise2])



            } catch (error) {

                if (error.errorMessage == "USER_NOT_PARTICIPANT") {
                    IsMemberUser = false
                } else if (error.errorMessage == 'FLOOD') {

                    let LimitTime = error.seconds * 1000
                    let FinalLimit = Date.now() + LimitTime
                    // console.log(this.ID)
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(this.ID) }).modify({ FloodWait: FinalLimit })
                    } catch (error) {
                        // console.log(error)
                    }

                    IsMemberUser = 'Flood'
                } else if (error.errorMessage == 'AUTH_KEY_UNREGISTERED' || error.errorMessage == "USER_DEACTIVATED_BAN" || error.errorMessage == "AUTH_KEY_DUPLICATED" || error.errorMessage == "AUTH_KEY_UNREGISTERED" || error.errorMessage == "SESSION_REVOKED") {
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ Session: false })
                        let re1s = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: false })

                        CountBannedLogOut()

                    } catch (error) {
                        // console.log(error)

                    }
                    CountBannedLogOut()
                    IsMemberUser = 'error'

                } else {
                    IsMemberUser = 'error'

                }
                // console.log(error.errorMessage)


            }


            return IsMemberUser

        },

        async Next(List) {
            // console.log(List)
            let Satatus
            let NewList = List.filter((item, i) => {
                if (i != 0) {
                    return item
                }
            })

            localStorage.setItem("ListForJoin" + this.ID, JSON.stringify(NewList))
            try {

                let res = await TelegramDB.Data.where({ id: Number(this.ID) }).modify({ ListForJoin: NewList })
                // console.log(res)


                if (res) {
                    // Proceed knowing the update is successful
                    // console.log(1)
                    return true
                } else {
                    // console.log(2)
                    return false
                    // Handle potential update failure (log error or retry)
                }
            } catch (error) {
                // console.log(error)
                return false


            }

        },
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async demo(time) {
            let status = false;
            for (let i = 0; i <= time; i++) {
                let TelegramAccountUpdate = await TelegramDB.Data.toArray()
                let Account
                TelegramAccountUpdate.map(item => {
                    if (item.id == this.ID) {
                        Account = item
                    }
                })

                if (Account.AutoJoin == true) {
                    await this.sleep(1000);
                    // console.log(`Waiting ${i} seconds...`);
                    status = true
                } else {
                    status = false
                    break
                }

            }
            return status
        },
        async CheckDialog(MyClient, UserName) {
            const dialogs = await MyClient.getDialogs();
            let res = false
            dialogs.forEach(
                async (dialog) => {
                    if (!dialog.isUser) {

                        let username = dialog.entity.username

                        if (username == UserName) {
                            // console.log(username, UserName)
                            res = true
                            // console.log(true + "is memeber//////////////////////////")
                            // let resNext = await this.Next(list)

                        }


                    }
                }

            )
            return res
        },
        async Loop(ID, AccountsLength) {
            // console.log(ID)
            let TelegramAccountUpdate = await TelegramDB.Data.toArray()
            let Account
            let Status = null
            TelegramAccountUpdate.map(item => {
                if (item.id == ID) {
                    Account = item
                    // dispatch(setListForJoin(Account.ListForJoin))
                    localStorage.setItem("ListForJoin" + ID, JSON.stringify(Account.ListForJoin))
                }
            })
            // console.log(Account)
            try {
                if (Account != undefined) {
                    if (Account.AutoJoin || Account.AutoJoinSingle) {
                        if (Account.ListForJoin) {

                            if (Account.ListForJoin.length > 0) {


                                let ConTimeout;
                                let ResConnection1
                                const myPromise1 = new Promise((resolve, reject) => {

                                    resolve(CheckConnection(Account.Session, Account.id));
                                    clearTimeout(ConTimeout)
                                });


                                const myPromise2 = new Promise((resolve, reject) => {
                                    ConTimeout = setTimeout(resolve, 71000, 'stop')
                                });


                                ResConnection1 = await Promise.race([myPromise1, myPromise2]).then((x) => {
                                    return x
                                });


                                // old call
                                // console.log(ResConnection1)


                                if (ResConnection1.status == true || ResConnection1.status == undefined && ResConnection1.Client != false && ResConnection1.Client != undefined && ResConnection1.Client != "undefined") {
                                    // Connection Ok
                                    if (Account.FloodWait == 0 || Account.FloodWait == undefined) {
                                        // Flood Wait false
                                        const MyClient = ResConnection1.Client
                                        if (Account.AutoJoin == true) {
                                            // Auto Join True
                                            // await MyClient.connect()
                                            let TelegramAccountUpdate = await TelegramDB.Data.toArray()

                                            let AccountUpdate
                                            TelegramAccountUpdate.map(item => {
                                                if (item.id == ID) {
                                                    AccountUpdate = item
                                                }
                                            })
                                            // console.log(AccountUpdate)
                                            let list = localStorage.getItem("ListForJoin" + ID)
                                            list = JSON.parse(list)
                                            // console.log(list)
                                            // let IsMember = await this.IsMember(MyClient, AccountUpdate.ListForJoin, ID, AccountUpdate.User);
                                            let IsMember = await this.IsMember(MyClient, list, ID, AccountUpdate.User);
                                            // console.log('auto join true ')
                                            // console.log(list)
                                            // console.log(AccountUpdate.User)
                                            // console.log(IsMember)
                                            CountBannedLogOut()
                                            // MyClient.destroy();

                                            // let speed = 5
                                            let speed = 1

                                            if (Account.SpeedJoin) {
                                                if (Account.SpeedJoin.Status == 'Slowly') {
                                                    let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                                                    // let random = 0
                                                    speed = random + 2
                                                } else if (Account.SpeedJoin.Status == 'Medium') {
                                                    let random = Math.floor(Math.random() * (2 - 0 + 1) + 0)
                                                    // let random = 0
                                                    speed = random + 1
                                                } else if (Account.SpeedJoin.Status == 'Quick') {
                                                    let random = Math.floor(Math.random() * (1 - 0 + 1) + 0)
                                                    // let random = 0
                                                    speed = random + 0
                                                } else if (Account.SpeedJoin.Status == 'Desired') {
                                                    speed = Account.SpeedJoin.SpeedOfJoin
                                                }
                                            } else {
                                                let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                                                speed = random + 1
                                                // speed = 1
                                            }
                                            // console.log(speed)

                                            if (IsMember == false) {
                                                // console.log(IsMember)
                                                // let DelayBeforeJoin = await this.demo(speed);
                                                let DelayBeforeJoin = true;
                                                if (DelayBeforeJoin == true) {
                                                    // console.log(list)
                                                    let TgChID = AccountUpdate.ListForJoin[0].tgChannelId
                                                    // let UserName = AccountUpdate.ListForJoin[0].username
                                                    let UserName = list[0].username
                                                    let BotSeS = AccountUpdate.BotSession
                                                    // let Id = AccountUpdate.ListForJoin[0].Id
                                                    let Id = list[0].Id
                                                    // let _id = AccountUpdate.ListForJoin[0]._id
                                                    let _id = list[0]._id
                                                    // let PhoneNumber = AccountUpdate.ListForJoin[0].PhoneNumber
                                                    let PhoneNumber = list[0].PhoneNumber


                                                    let dialogRes = await this.CheckDialog(MyClient, UserName)
                                                    // console.log(dialogRes)
                                                    // console.log(dialogRes)
                                                    let res


                                                    if (dialogRes == false) {
                                                        res = await this.Join(MyClient, list, Account.Session, Account.id)
                                                        // console.log(res)
                                                    } else {

                                                        res = false
                                                        // Status = { Repeat: true, Status: 'error 309' }
                                                    }
                                                    // console.log(res)

                                                    CountBannedLogOut()
                                                    // MyClient.destroy();

                                                    // console.log(res)
                                                    if (res.chats) {

                                                        const MakeApiClass = new AllApi();

                                                        let tgChannelIdFromResponseJoin = res.chats[0].id.value.toString().replace('n', '');


                                                        let JoinResponse
                                                        if (dialogRes == false) {
                                                            JoinResponse = await MakeApiClass.JoinChannel(Number(tgChannelIdFromResponseJoin), UserName, Token, BotSeS, Id, _id)
                                                            // console.log(JoinResponse)
                                                            dispatch(SetXpCount(JoinResponse.data.user.xpCount))
                                                            dispatch(SetxpCountLeague(JoinResponse.data.user.xpCountLeague))

                                                        } else {

                                                            JoinResponse = false
                                                        }


                                                        if (JoinResponse.code == 309) {

                                                            let resNext = await this.Next(list)
                                                            // console.log(resNext)
                                                            Status = { Repeat: true, Status: 'error 309' }

                                                        } else if (JoinResponse.code == 200) {
                                                            // console.log(AccountUpdate.id)
                                                            setAnimationJoin((prevElements) => [
                                                                ...prevElements,
                                                                {
                                                                    id: AccountUpdate.id, // Generate a unique ID, e.g., using the current timestamp
                                                                    element: (
                                                                        <div className='AnimationContainer'>
                                                                            <div className='AnimationCoinList'>
                                                                                +{localStorage.getItem("joinCoinRewards")} <img src={coinplus} className='AnimationCoinNewUserBottomSheetIcon' />
                                                                            </div>
                                                                            <div className='AnimationXpList'>
                                                                                +{localStorage.getItem("joinXpRewards")} <img src={xpplus} className='AnimationXpNewUserBottomSheetIcon' />
                                                                            </div>
                                                                        </div>
                                                                    ),
                                                                },
                                                            ])
                                                            // dispatch(SetAccountIsFilled({ status: false, id: ID }))
                                                            dispatch(SetJoinCountRedux(JoinResponse.data.user.joinCount))
                                                            // console.log(JoinResponse)
                                                            // let resNext = await this.Next(AccountUpdate.ListForJoin)
                                                            let resNext = await this.Next(list)
                                                            // console.log(resNext)
                                                            if (AccountUpdate.AutoJoin == true) {
                                                                dispatch(setUserCoin(JoinResponse.data.user.coins))
                                                                let LiveCoinVar = localStorage.getItem(AccountUpdate.id + "LiveCoin")
                                                                // console.log(LiveCoinVar)
                                                                if (LiveCoinVar == 0 || LiveCoinVar == '0') {
                                                                    localStorage.setItem(AccountUpdate.id + "LiveCoin", 2)
                                                                } else {
                                                                    localStorage.setItem(AccountUpdate.id + "LiveCoin", Number(LiveCoinVar) + 2)
                                                                }
                                                                try {
                                                                    let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: false })
                                                                } catch (error) {
                                                                    // console.log(error)
                                                                }
                                                                try {
                                                                    TelegramDB.Data.where({ id: Number(AccountUpdate.id) }).modify({ TooMuchLeft: false })
                                                                } catch (error) {
                                                                    // console.log(`Failed to add ${'Session'}: ${error}`);
                                                                }
                                                                // dispatch(SetAccountIsFilled({ status: true, id: ID }))
                                                                Status = { Repeat: true, status: 'ok', Coins: JoinResponse.data.user.coins }

                                                            }
                                                        } else if (JoinResponse.code == 120 || JoinResponse.code == 121 || JoinResponse.code == 122 || JoinResponse.code == 123) {
                                                            let name = "Token"
                                                            let name1 = "active"
                                                            document.cookie = name + '=; Max-Age=-99999999;';
                                                            document.cookie = name1 + '=; Max-Age=-99999999;';
                                                            history.push("/");
                                                            // dispatch(setLoading(false))

                                                        } else {
                                                            Status = { Repeat: true, status: 'join response not ok', Coins: null }

                                                        }
                                                    } else if (res.errorMessage == 'FLOOD') {
                                                        MyClient.destroy();



                                                        CheckAccounts()
                                                        // console.log(autoJoinAccounts)

                                                        CheckFloodWait(Account, autoJoinAccounts)
                                                        Status = { Repeat: false, Status: res.errorMessage }
                                                    } else if (res.errorMessage == "CHANNELS_TOO_MUCH") {
                                                        // dispatch(SetAccountIsFilled({ status: true, id: ID }))
                                                        try {
                                                            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: true })
                                                        } catch (error) {
                                                            // console.log(error)
                                                        }
                                                        try {
                                                            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: true })
                                                        } catch (error) {
                                                            // console.log(error)
                                                        }
                                                        CheckAccounts()

                                                        Status = { Repeat: true, Status: 'CHANNELS_TOO_MUCH' }
                                                    } else if (res == "errorConnection") {
                                                        // console.log(res)
                                                        Status = { Repeat: true, Status: res }
                                                        MyClient.destroy();
                                                    } else if (res.errorMessage == 'AUTH_KEY_UNREGISTERED' || res.errorMessage == "USER_DEACTIVATED_BAN" || res.errorMessage == "AUTH_KEY_DUPLICATED" || res.errorMessage == "AUTH_KEY_UNREGISTERED") {
                                                        // const id = await TelegramDB.Data.delete(ItemID);
                                                        let res = await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ Session: false })
                                                        let re1s = await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ AutoJoin: false })
                                                        Status = { Repeat: false, Status: "account logout" }
                                                        // CheckLimit(Account)
                                                    }
                                                    else {
                                                        // console.log(res + 'eroooorrrrr')
                                                        Status = { Repeat: true, Status: res }
                                                    }



                                                }
                                            } else if (IsMember == "Flood") {
                                                MyClient.destroy();
                                                CheckAccounts()
                                                // console.log(autoJoinAccounts)

                                                CheckFloodWait(Account, autoJoinAccounts)
                                                Status = { Repeat: true, Status: "Flood" }
                                            } else if (IsMember == "errorConnection") {
                                                // console.log(IsMember)
                                                Status = { Repeat: true, Status: 'errorConnection' }
                                                try {
                                                    // console.log('disconnect')
                                                    await MyClient.disconnect();
                                                } catch (error) {
                                                    // console.log(error)
                                                }
                                                try {
                                                    // console.log('destroy')

                                                    await MyClient.destroy();

                                                } catch (error) {
                                                    // console.log(error)

                                                }


                                            } else if (IsMember == "errorCheck") {
                                                await MyClient.destroy();
                                                // try {
                                                // console.log(IsMember)
                                                handleClick({ vertical: 'bottom', horizontal: 'center' })

                                                Status = { Repeat: true, Status: 'errorConnection' }
                                            } else if (IsMember == true) {
                                                //   let resNext = await this.Next(AccountUpdate.ListForJoin)
                                                let resNext = await this.Next(list)

                                                // console.log(resNext)
                                                Status = { Repeat: true, Status: 'is member true' }
                                            }

                                            else {

                                                let DelayBeforeJoin = await this.demo(1);
                                                if (DelayBeforeJoin) {
                                                    // console.log('Next')
                                                    // let resNext = await this.Next(AccountUpdate.ListForJoin)
                                                    let resNext = await this.Next(list)

                                                    // console.log(resNext)
                                                    Status = { Repeat: true, Status: 'is member true' }
                                                }

                                            }
                                        } else {
                                            // console.log(Account)
                                        }
                                    } else {
                                        // Has FloodWait

                                        ResConnection1.Client.destroy();

                                        // console.log(autoJoinAccounts)

                                        CheckFloodWait(Account, autoJoinAccounts)
                                        Status = { Repeat: false, Status: 'Accountlimit' }
                                    }
                                } else {
                                    // Connection Failed
                                    // console.log(ResConnection1)

                                    if (ResConnection1.status == "logout") {
                                        Status = { Repeat: false, Status: 'logout' }

                                    } else {

                                        // handleClick({ vertical: 'bottom', horizontal: 'center' })

                                        let MyItem = await TelegramDB.Data.toArray()
                                        MyItem.map(item => {
                                            if (item.id == Account.id) {
                                                if (item.AutoJoin == true) {
                                                    Status = { Repeat: true, Status: 'connection' }
                                                }

                                            }
                                        })

                                    }


                                }
                            } else {
                                // Get List (Empty)
                                let res = await GetListFromApi(Account.BotSession, Account.id, Account.Session)
                                // console.log(res)
                                if (res == true) {
                                    Status = { Repeat: true, Status: 'GetListFromApi' }

                                }
                                else if (res == false) {
                                    Status = { Repeat: false, Status: 'GetListFromApi' }
                                    try {
                                        await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ AutoJoin: false })
                                    } catch (error) {
                                        // console.log(`Failed to add ${'Session'}: ${error}`);
                                    }
                                } else {
                                    // console.log(1314)
                                }
                            }
                        } else {
                            // Get List (undefined)
                            let res = await GetListFromApi(Account.BotSession, Account.id, Account.Session)
                            // console.log(res)
                            if (res == true) {
                                Status = { Repeat: true, Status: 'GetListFromApi' }

                            } else if (res == false) {
                                Status = { Repeat: false, Status: 'GetListFromApi' }
                                try {
                                    await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ AutoJoin: false })
                                } catch (error) {
                                    // console.log(`Failed to add ${'Session'}: ${error}`);
                                }
                            } else {
                                // console.log(1330)
                            }

                        }
                    } else {
                        Status = { Repeat: false, Status: 'Account stop join' }

                    }

                } else {
                    Status = { Repeat: false, Status: 'Account not found' }

                }

            } catch (error) {
                // console.log(error)
                if (error.errorMessage == "CHANNELS_TOO_MUCH") {
                    dispatch(SetAccountIsFilled({ status: true, id: ID }))
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: true })
                    } catch (error) {
                        // console.log(error)
                    }
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: true })
                    } catch (error) {
                        // console.log(error)
                    }
                    CheckAccounts()

                    Status = { Repeat: true, Status: 'CHANNELS_TOO_MUCH' }
                } else {
                    Status = { Repeat: true, Status: error.errorMessage }

                }
            }

            // End Loop And Check

            CountBannedLogOut()

            // console.log(Status)

            // console.log(ID)

            if (Status != null && Status.Repeat == true) {
                if (Status.Status == 'connection' || Status.Status == 'errorConnection') {
                    setTimeout(() => {
                        this.Loop(ID)

                    }, 5000);
                } else {
                    this.Loop(ID)

                }

                // this.Loop(ID)


            }
            // console.log('end loop')
        },
    }
    const StartOneAccountJoin = async (AccountAutoJoinIsTrue) => {
        // console.log('StartOneAccountJoin')
        setAutoJoinStatus(true);
        // console.log('auto join start ')
        // console.log(AccountAutoJoinIsTrue)
        // dispatch(setAutoJoinLocal({ id:AccountAutoJoinIsTrue.id, Autojoin: true }));
        // dispatch({
        //     type: "SET_AUTO_JOIN",
        //     payload: { id: AccountAutoJoinIsTrue.id, Autojoin: true },
        // });
        // console.log(autoJoinAccounts);
        dispatch(addOrUpdateAutoJoinAccount({ id: AccountAutoJoinIsTrue.id, AutoJoin: true }));

        try {
            await TelegramDB.Data.where({ id: Number(AccountAutoJoinIsTrue.id) }).modify({ AutoJoinSingle: false });
        } catch (error) {
            console.error("Database update error:", error);
        }

        CheckAccounts();

        const { Session, ListForJoin, id: ID, User } = AccountAutoJoinIsTrue;
        const UserName = User?.username || "Unknown";

        const MyAccount = Object.create(AutoManualJoinObject);
        MyAccount.Session = Session;
        MyAccount.ListForJoin = ListForJoin;
        MyAccount.UserName = UserName;
        MyAccount.ID = ID;

        SetAutoJoinDB(ID);

        let JoinTimeout;

        const JoinPromise1 = new Promise((resolve) => {
            resolve(CheckConnection(Session, ID));
        });

        const JoinPromise2 = new Promise((resolve) => {
            JoinTimeout = setTimeout(() => resolve({ status: "errorConnection" }), 10000);
        });

        const ResConnection = await Promise.race([JoinPromise1, JoinPromise2]);

        clearTimeout(JoinTimeout);
        // console.log(ResConnection)

        if (ResConnection?.status === true || (ResConnection?.status === undefined && ResConnection?.Client !== false)) {
            // if (true) {
            dispatch(setMiniLoading(false));
            try {
                MyAccount.Loop(ID, AccountAutoJoinIsTrue.length);
            } catch (error) {
                console.error("Loop execution error:", error);
            }
        } else {
            // console.log(ResConnection)
            if (ResConnection?.status !== "logout" && ResConnection?.status !== "errorConnection") {
                // console.log('snack')
                handleClick({ vertical: "bottom", horizontal: "center" });
                dispatch(setMiniLoading(false));

                const MyItem = await TelegramDB.Data.toArray();
                // console.log(MyItem)
                // setTimeout(() => {
                MyItem.forEach(item => {
                    if (item.id === ID && item.AutoJoin == true) {

                        // console.log('start account taki')
                        autoJoinAccounts.map(Ritem => {
                            if (Ritem.id == item.id) {
                                if (Ritem.AutoJoin == false) {
                                    // console.log('run taki') 
                                    dispatch(addOrUpdateAutoJoinAccount({ id: item.id, AutoJoin: true }));

                                    StartOneAccountJoin(AccountAutoJoinIsTrue);
                                }
                            }
                        })
                        // StartOneAccountJoin(AccountAutoJoinIsTrue);
                    }
                });
                // }, 4000);
            }

            CountBannedLogOut();
        }

    };
    const StopAllAccountJoin = async () => {
        setAnimationJoin([]);
        setAutoJoinStatus(false);


        let TelegramAccountUpdate = await TelegramDB.Data.toArray();

        // غیرفعال کردن AutoJoin در localStorage
        TelegramAccountUpdate.forEach(({ id }) => {
            localStorage.setItem(`${id}LiveCoin`, 0);
            dispatch(addOrUpdateAutoJoinAccount({ id, AutoJoin: true }));
        });

        // فیلتر کردن اکانت‌هایی که Session دارند
        let accountsWithSession = TelegramAccountUpdate.filter(({ Session }) => Session);

        try {
            // غیر فعال کردن AutoJoin در Redux
            accountsWithSession.forEach(({ id }) => {
                // dispatch(setAutoJoinLocal({ id, Autojoin: false }));



            });
            setTimeout(() => {
                // console.log(234)
            }, 4000);
            // آپدیت دیتابیس برای همه حساب‌ها
            await Promise.all(
                accountsWithSession.map(({ id }) =>
                    TelegramDB.Data.where({ id: Number(id) }).modify({
                        AutoJoin: false,
                        AutoJoinSingle: false
                    })
                )
            );

            // بعد از آپدیت، دیتابیس رو بخون و بررسی کن که تغییرات ذخیره شده
            let updatedAccounts = await TelegramDB.Data.toArray();
            // console.log("Updated Accounts:", updatedAccounts);

        } catch (error) {
            console.error(`Failed to update AutoJoin status:`, error);
        }

        setAutoJoinStatus(false);
    };


    const StartAllAccountJoin = async (Status) => {
        // console.log('auto join start ')
        setAutoJoinStatus(true);
        await CheckAccounts();

        setAnimationJoin([]);
        dispatch(setMiniLoading(true));
        // console.log("StartAllAccountJoin")
        let TelegramAccountUpdate = await TelegramDB.Data.toArray();
        TelegramAccountUpdate.forEach(item => SetAutoJoinDB(item.id));

        for (const account of TelegramAccountUpdate) {
            const { id, Session, ListForJoin, User } = account;
            const UserName = User?.username;

            try {
                await TelegramDB.Data.where({ id: Number(id) }).modify({ LiveCoinState: 0 });
            } catch (error) {
                console.error("Error updating LiveCoinState:", error);
            }

            if (!Session) continue;

            const MyAccount = Object.create(AutoManualJoinObject);
            Object.assign(MyAccount, { Session, ListForJoin, UserName, ID: id });

            try {
                // let JoinTimeout;
                // const ResConnection = await Promise.race([
                //     new Promise(resolve => resolve(CheckConnection(Session, id))),
                //     new Promise(resolve => {
                //         JoinTimeout = setTimeout(() => resolve({ status: false }), 10000);
                //     })
                // ]);

                // clearTimeout(JoinTimeout);
                // console.log(ResConnection) 
                let JoinTimeout;

                const JoinPromise1 = new Promise((resolve) => {
                    resolve(CheckConnection(Session));
                });

                const JoinPromise2 = new Promise((resolve) => {
                    JoinTimeout = setTimeout(() => resolve({ status: "errorConnection" }), 10000);
                });

                const ResConnection = await Promise.race([JoinPromise1, JoinPromise2]);

                clearTimeout(JoinTimeout);
                // console.log(ResConnection)
                if (ResConnection?.status || (ResConnection?.status === undefined && ResConnection?.Client)) {
                    dispatch(setMiniLoading(false));
                    // console.log(account)
                    // console.log("StartAllAccountJoin")
                    // console.log('auto join start ')

                    // setAutoJoinStatus(true);
                    try {
                        // console.log("myloop")

                        await MyAccount.Loop(id, TelegramAccountUpdate.length);
                    } catch (error) {
                        console.error("Error in MyAccount.Loop:", error);
                    }
                } else if (ResConnection?.status !== false) {
                    // console.log('first')

                    // handleClick({ vertical: 'bottom', horizontal: 'center' });
                    dispatch(setMiniLoading(false));
                    setAutoJoinStatus(false);

                    setTimeout(async () => {
                        let MyItem = await TelegramDB.Data.toArray();
                        MyItem.forEach(item => {
                            if (item.id === id && item.AutoJoin) {
                                // console.log('1225')
                                StartAllAccountJoin('1225');
                            }
                        });
                    }, 5000);
                }
            } catch (error) {
                console.error("Error handling connection:", error);
            }


        }
    };




    const GetListFromApi = async (BotSession, id, Session) => {


        if (BotSession) {

            // let res = await GetChannels(Token, BotSession)
            const MakeApiClass = new AllApi();
            let res = await MakeApiClass.GetChannels(Token, BotSession)
            // console.log(res)
            if (res.data.limit == 'limit') {
                // setTooMuchLeft({ status: true, id: id })
                // dispatch(SetTooMuchLeft({ status: true, id: id }))
                try {
                    TelegramDB.Data.where({ id: Number(id) }).modify({ TooMuchLeft: true })
                } catch (error) {
                    // console.log(`Failed to add ${'Session'}: ${error}`);
                }


                return false
            } else {
                if (res.code == 200) {
                    // console.log(res)
                    AddTelegramSession(res.data.data, id)
                    return true
                } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                    let name = "Token"
                    let name1 = "active"
                    document.cookie = name + '=; Max-Age=-99999999;';
                    document.cookie = name1 + '=; Max-Age=-99999999;';
                    history.push("/");
                    // dispatch(setLoading(false))

                }
            }

        }
    }
    const AddTelegramSession = async (List, ID) => {
        try {
            TelegramDB.Data.where({ id: Number(ID) }).modify({ ListForJoin: List })
        } catch (error) {
            // console.log(`Failed to add ${'Session'}: ${error}`);
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarLogout(false);
    };


    const CheckFloodWait = async (Accountlimit) => {
        let item;
        // const autoJoinAccounts = useSelector(state => state.JoinState.autoJoinAccounts); // ✅ Always gets latest state
        let autoJoinAccounts =localStorage.getItem('autoJoinAccounts') 
        // console.log(autoJoinAccounts)
         autoJoinAccounts =JSON.parse(autoJoinAccounts) 
        // console.log(autoJoinAccounts)
        // Fetch data from the database
        const TelegramAccountUpdatelimit = await TelegramDB.Data.toArray();

        // Find the account that matches the given Accountlimit id
        item = TelegramAccountUpdatelimit.find(item1 => item1.id === Number(Accountlimit.id));

        if (!item) {
            console.error('Account not found');
            return;
        }

        const Now = Date.now();
        let Status = null;

        try {
            // If there is a FloodWait, check the conditions
            if (item.FloodWait !== null) {
                if (Now < item.FloodWait) {
                    // Calculate remaining wait time in seconds
                    let LimitTimeMiliSecond = (item.FloodWait - Now) / 1000;

                    // Set flood wait state to true
                    setFloodWaitState(true);
                    Status = true;

                    // Wait for the flood wait time to be over
                    const res = await timer(LimitTimeMiliSecond, item.id, item);

                    if (res === true) {
                        // Reset FloodWait and state after the wait time
                        await TelegramDB.Data.where({ id: Number(item.id) }).modify({ FloodWait: 0 });
                        setFloodWaitState(false);

                        // Optionally, continue with auto join logic if enabled
                        if (item.AutoJoin || item.AutoJoinSingle) {
                            // console.log('check flood');
                            autoJoinAccounts.map(Ritem => {
                                if (Ritem.id == item.id) {
                                    if (Ritem.AutoJoin == false) {
                                        // console.log('run taki')  
                                        dispatch(addOrUpdateAutoJoinAccount({ id: item.id, AutoJoin: true }));
    
                                        StartOneAccountJoin(item);
                                    }
                                }
                            })
    
                        }
                    }
                } else {
                    // FloodWait time has passed, reset it
                    setFloodWaitState(false);
                    Status = false;
                    await TelegramDB.Data.where({ id: Number(item.id) }).modify({ FloodWait: 0 });
                    // const accounts = fetchAutoJoinAccounts();
                    // console.log("🚀 Used inside another function:", autoJoinAccounts);                 // Start account join process if applicable
                    // console.log( autoJoinAccounts);                 // Start account join process if applicable
                    if (item.AutoJoin || item.AutoJoinSingle) {
                        // console.log('check flood 2');
                        autoJoinAccounts.map(Ritem => {
                            if (Ritem.id == item.id) {
                                if (Ritem.AutoJoin == false) {
                                    // console.log('run taki')  
                                    dispatch(addOrUpdateAutoJoinAccount({ id: item.id, AutoJoin: true }));

                                    StartOneAccountJoin(item);
                                }
                            }
                        })



                        // dispatch(addOrUpdateAutoJoinAccount({ id:item.id, AutoJoin: true }));

                        // if (AutoJoinStatus == false) {
                        // console.log('run taki')
                        //    StartOneAccountJoin(item); 
                        // }


                    }
                }

            } else {
                // No FloodWait, proceed with account joining directly
                setFloodWaitState(false);
                Status = false;

                if (item.AutoJoin || item.AutoJoinSingle) {
                    // console.log('check flood 3');
                    autoJoinAccounts.map(Ritem => {
                        if (Ritem.id == item.id) {
                            if (Ritem.AutoJoin == false) {
                                // console.log('run taki')  
                                dispatch(addOrUpdateAutoJoinAccount({ id: item.id, AutoJoin: true }));

                                StartOneAccountJoin(item);
                            }
                        }
                    })

                }
            }
        } catch (error) {
            console.error('Error in timer or database update:', error);
            setFloodWaitState(false);  // Ensure the state is reset in case of an error
            Status = false;  // Ensure the status is returned as false
        }

        return Status;
    };

    const sleep = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const timer = async (limit, id, item) => {
        let status = false;

        // Start looping until the limit is reached
        for (let i = 0; i <= limit; i++) {
            // Fetch the account data
            let TelegramAccountUpdate = await TelegramDB.Data.toArray();
            let Account = TelegramAccountUpdate.find(item => item.id == id);

            // If account is found and AutoJoin is true, continue
            if (Account && Account.AutoJoin) {
                // Wait for 1 second before checking again
                await sleep(1000);

                status = true;
            } else {
                // If AutoJoin is false, break out of the loop
                status = false;
                break;
            }
        }

        // Return status after all checks are done
        return status;
    };

    const CheckLimit = async (Accountlimit) => {
        let Now = Date.now();
        let Status = null;
        let item;

        // Get the account data from the database
        const TelegramAccountUpdatelimit = await TelegramDB.Data.toArray();
        item = TelegramAccountUpdatelimit.find(item1 => Accountlimit.id === item1.id);

        // If the item was found
        if (item) {
            if (item.FloodWait !== null) {
                if (Now < item.FloodWait) {
                    // If FloodWait is still active
                    let LimitTimeMiliSecond = (item.FloodWait - Now) / 1000; // convert to seconds

                    // Set the flood wait state
                    setFloodWaitState(true);
                    Status = true;

                    // Optionally, you could start a timer here to manage the wait time
                    // timer3(LimitTimeMiliSecond, item.id, item);

                } else {
                    // FloodWait has passed, reset the state and modify FloodWait in DB
                    setFloodWaitState(false);
                    Status = false;

                    try {
                        await TelegramDB.Data.where({ id: Number(item.id) }).modify({ FloodWait: 0 });
                    } catch (error) {
                        console.error('Error resetting FloodWait:', error);
                    }

                    // Proceed with the account's next actions
                    // console.log(autoJoinAccounts)

                    CheckFloodWait(Accountlimit, autoJoinAccounts);
                    CheckAccounts();
                }
            } else {
                // If there is no FloodWait, continue without setting flood wait state
                Status = false;
            }
        }

        return Status;
    };

    const Logout = async (id, phone) => {
        setPhoneForLogout(phone)
        setIdForLogout(id)
        setShowLogOut(true)
    }
    const ConfirmLogOut = async (id) => {
        // console.log(id)
        localStorage.setItem('Banned', JSON.stringify(['']))

        setLoadingForLogOut(true)
        try {
            const id1 = await TelegramDB.Data.delete(id);
            // console.log(id1)
        } catch (error) {
            // console.log(`Failed to delete ${id}: ${error}`);
        }
        setLoadingForLogOut(false)
        setShowLogOut(false)
        CountBannedLogOut()
    }
    function onDismiss() {
        setTimeout(() => {
            setShowLogOut(false)
        }, 300);
    }
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose1 = () => {
        setState({ ...state, opens: false });
    };


    let Banned = JSON.parse(localStorage.getItem('Banned'))

    const deepEqual = (arr1, arr2) => {
        if (arr1 === arr2) return true; // Handle same object case

        if (arr1.length !== arr2.length) return false; // Different lengths

        for (let i = 0; i < arr1.length; i++) {
            // Check if element types are the same (primitive vs. object)
            if (typeof arr1[i] !== typeof arr2[i]) return false;

            // If primitive types, compare directly
            if (typeof arr1[i] === 'primitive') {
                if (arr1[i] !== arr2[i]) return false;
                continue;
            }

            // If objects, perform recursive deep comparison
            if (!deepEqual(arr1[i], arr2[i])) return false;
        }

        return true; // All elements match
    }

    useEffect(() => {

        if (Banny == undefined) {

            CountBannedLogOut()

        } else {
            if (deepEqual(Banned, Banny)) {

            } else {
                CountBannedLogOut()
            }
        }

        Banned = JSON.parse(localStorage.getItem('Banned'))
    }, [Banned])
    useEffect(() => {


        setLoadingState(true)
        CheckAccounts()
        setTimeout(() => {
            setLoadingState(false)

        }, 1000);
        clearInterval(timer1use)
        clearInterval(window.timer2)
        timeruse()
        checkLiveCoin()
        setAnimationJoin([]);

    }, [])



    const checkLiveCoin = async () => {
        let res = await GetUpdatedTelegramAccont()
        // console.log(res)
        if (res.length > 0) {
            res.map((item, i) => {
                let LiveCoinVar = localStorage.getItem(item.id + "LiveCoin")
                if (LiveCoinVar == 'null' || LiveCoinVar == undefined) {
                    localStorage.setItem(item.id + "LiveCoin", 0)
                }
            }
            )
        }

    }
    let timer1use
    const timeruse = async () => {
        let res = await GetUpdatedTelegramAccont()
        // console.log(res)
        timer1use = setInterval(function () {

            if (res.length > 0) {

                res.map((item, i) => {

                    CheckLimit(item)
                    if (item.AutoJoin == true) {
                        // clearInterval(window.timer1)
                    }


                }
                )
            }
            CountBannedLogOut()
            CheckAccounts()

        }, 10000);

    }
// console.log(AutoJoinStatus ,CountJoining )
    return (
        <>
            {
                LoadingState ?
                    <Loading />
                    :

                    <div className='AccountList1'>

                        <Snackbar
                            open={SnackbarLogout}
                            autoHideDuration={2000}
                            onClose={handleClose}
                            message="Something went wrong! Please try again."

                        />
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={opens}
                            onClose={handleClose1}
                            // message={` Password changed`}
                            // key={vertical + horizontal}
                            autoHideDuration={2000}
                            message={<span id="client-snackbar">
                                <WifiOffIcon className='WifiIcon' /> No internet connection</span>
                            }

                        />
                        <div className='AccountListContainer'>
                            {/* CountJoiningLoading ? */}
                            {/* <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' /> */}

                            {
                                joinLeagueEnable && !leagueWithXp ?
                                    <GameCoin />
                                    : null
                            }

                            {
                                AccountCount > 0 ?
                                    <div className='AccountListHeader'  >
                                        <div className='AccountListHeaderItem1'>
                                            <span className='AccountListHeaderItem1Inner'>
                                                Accounts
                                            </span>


                                        </div>


                                        <div className='AccountListHeaderItem1'>
                                            <span className='AccountListHeaderItem1Inner'>
                                                {CountJoiningLoading ? <Skeleton width={55} height={24} style={{ 'marginLeft': '4px', 'borderRadius': '8px' }} />
                                                    :
                                                    // ' ( ' + CountJoining + ' / ' + AccountCount + ' ) '
                                                    CountJoining

                                                }
                                            </span>

                                            &nbsp;Joining accounts </div>
                                    </div>
                                    :
                                    null
                            }





                            {
                                TelegramAccount ?
                                    TelegramAccount.length > 0 ?
                                        TelegramAccount.map((item, i) =>

                                            item.Session != false ?


                                                item.BotSession != '' ?
                                                    // CheckLimit(item)
                                                    // &&
                                                    <>
                                                        <Link to={`/AutoManualJoin?id=${item.id}`} key={i + 'link'}
                                                            className='AccountListItemActive' >
                                                            {/* <div className='AnimationContainer'>
                                                                <div className='AnimationCoinList'>
                                                                    +2 <img src={coinplus} className='AnimationCoinNewUserBottomSheetIcon' />
                                                                </div>
                                                                <div className='AnimationXpList'>
                                                                    +15 <img src={xpplus} className='AnimationXpNewUserBottomSheetIcon' />
                                                                </div>
                                                            </div> */}

                                                            {
                                                                AnimationJoin.map(Animationitem =>
                                                                    Animationitem.id == item.id ?
                                                                        Animationitem.element
                                                                        :
                                                                        null
                                                                )

                                                            }
                                                            <div className="AccountListItemActiveItem1" >


                                                                <div className='AccountListItemActiveImageContainer'>

                                                                    {
                                                                        // item.FloodWait == 0 || item.FloodWait == undefined ?
                                                                        item.CHANNELS_TOO_MUCH == false || item.CHANNELS_TOO_MUCH == undefined ?
                                                                            item.TooMuchLeft == false || item.TooMuchLeft == undefined ?
                                                                                item.AutoJoin || item.AutoJoinSingle ?
                                                                                    <div className="AccountListItemActiveImageJoiningLoader">

                                                                                    </div>
                                                                                    : null
                                                                                : null
                                                                            : null
                                                                        // : null
                                                                    }

                                                                    {item.ProfilePhoto ?
                                                                        item.ProfilePhoto == "data:text/plain;base64," ?
                                                                            <div className='ProfilePhotoTextContainer'>
                                                                                <span className='ProfilePhotoText' style={
                                                                                    // (item.FloodWait == 0 || item.FloodWait == undefined) && 
                                                                                    item.CHANNELS_TOO_MUCH != true && item.TooMuchLeft != true
                                                                                        && (item.AutoJoin == true || item.AutoJoinSingle) ?

                                                                                        { "width": "32px", "height": "32px" }
                                                                                        :
                                                                                        { "width": "44px", "height": "44px" }
                                                                                    // { "width": "44px", "height": "44px" }
                                                                                    // :

                                                                                }
                                                                                >
                                                                                    {item.User.firstName.charAt(0).toUpperCase()}
                                                                                </span>
                                                                            </div>
                                                                            // ||item.CHANNELS_TOO_MUCH==false
                                                                            :
                                                                            <img src={item.ProfilePhoto} className={item.CHANNELS_TOO_MUCH != true && item.TooMuchLeft != true ? item.AutoJoin == true || item.AutoJoinSingle == true ? 'AccountListItemActiveImageJoining' : 'AccountListItemActiveImage' : 'AccountListItemActiveImage'} />
                                                                        :
                                                                        <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' />
                                                                    }
                                                                </div>
                                                                <div className='AccountListItemActiveNamePhone'>
                                                                    <div className='AccountListItemActiveName'>{item.User.firstName ? item.User.firstName : null}
                                                                        {item.FloodWait > 0 || (item.CHANNELS_TOO_MUCH == true) || (item.TooMuchLeft == true) ? <span className='RestrictSpan'>Restrict</span> : null}

                                                                    </div>
                                                                    <div className='AccountListItemActivePhone'>
                                                                        {
                                                                            item.CountryName ?
                                                                                < ReactCountryFlag
                                                                                    // key={j}
                                                                                    countryCode={item.CountryName}
                                                                                    className='FlagPhoneNumber'
                                                                                    svg
                                                                                    title={item.CountryName}
                                                                                />

                                                                                : null
                                                                        }

                                                                        {
                                                                            // formatPhoneNumber('+12345678900')
                                                                            // formatPhoneNumber(
                                                                            '+' + item.PhoneNumber
                                                                            // )
                                                                        }</div>
                                                                </div>
                                                            </div>
                                                            <div>

                                                                {/* {
                                                                    'wait' + '='+
                                                                
                                                                    item.FloodWaitSecond
                                                                } */}


                                                            </div>
                                                            <div>

                                                                {
                                                                    // 'coin:' +
                                                                    // localStorage.getItem(item.id + "LiveCoin")
                                                                }
                                                            </div>
                                                            <div className='AccountListItemActiveItem2' >
                                                                <ArrowForwardIosIcon className='AccountListItemArrowRight' />
                                                            </div>
                                                        </Link>

                                                    </>




                                                    : null
                                                : null
                                        )
                                        :
                                        null
                                    : null

                            }
                            <div className='AccountListHeader'>
                                {
                                    Banned ?
                                        Banned.length > 0 ?

                                            <div className='AccountListHeaderItem1' >Banned & logged out</div>
                                            : null
                                        : null

                                }


                            </div>
                            {
                                //    let Banned=Banned 

                                Banned ?
                                    Banned.length > 0 ?
                                        Banned.map((item, i) =>
                                            item.Session == false ?
                                                <div className='AccountListItemDEActive' key={i + item.id}>

                                                    <div className="AccountListItemActiveItem1"  >
                                                        <div className='AccountListItemActiveImageContainer' >
                                                            {item.ProfilePhoto ?
                                                                item.ProfilePhoto == "data:text/plain;base64," ?
                                                                    <div className='ProfilePhotoTextContainer'>
                                                                        <span className='ProfilePhotoText' style={{ "width": "44px", "height": "44px", "opacity": "0.6" }}>
                                                                            {item.User.firstName.charAt(0).toUpperCase()}
                                                                        </span>
                                                                    </div>

                                                                    :
                                                                    <img src={item.ProfilePhoto} className={'AccountListItemActiveImage'} style={{ "opacity": "0.6" }} />
                                                                :
                                                                <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' style={{ "opacity": "0.6" }} />
                                                            }
                                                            {/* {item.ProfilePhoto ?
                                                                <img src={item.ProfilePhoto} className='AccountListItemActiveImage' />
                                                                :
                                                                <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' />

                                                            } */}

                                                        </div>
                                                        <div className='AccountListItemActiveNamePhone'>
                                                            <div className='AccountListItemActiveName' style={{ "opacity": "0.6" }}>{item.User.firstName ? item.User.firstName : null}
                                                                {/* <span className='RestrictSpan'>Restrict</span> */}
                                                            </div>
                                                            <div className='AccountListItemActivePhone' style={{ "opacity": "0.6" }}>
                                                                {
                                                                    item.CountryName ?

                                                                        < ReactCountryFlag

                                                                            countryCode={item.CountryName}
                                                                            className='FlagPhoneNumber'
                                                                            svg
                                                                            title={item.CountryName}
                                                                        />


                                                                        : null
                                                                }
                                                                +{item.PhoneNumber}</div>
                                                        </div>
                                                    </div>

                                                    <div className='AccountListItemActiveItem2'>
                                                        <button className='AccountListItemRemove' onClick={() => Logout(item.id, item.PhoneNumber)} >
                                                            Delete
                                                        </button>

                                                    </div>
                                                </div>
                                                : null
                                        )
                                        : null
                                    // <div className="loadminiBlue"></div>
                                    : null

                            }
                        </div>
                        {/* <div className='AddAccountAbsoluteButton'>
                            <div className='AddAccountAbsoluteButtonInnerContainer'> */}


                        <div className='AddAccountAndAutoJoinInList'>
                            <div className='AddAccountAndAutoJoinInListInner'>
                                <Link to='/LoginTelegram' className='AddAccoontButton2'>
                                    {/* <img className="plus15" src={plus15} /> */}
                                    {/* <img className="plus15" src={XPCoin} /> */}
                                    <div className='plus15'>
                                        {
                                            AddCoinFirstregister > 0 ?
                                                AddXpFirstregister > 0 ?
                                                    <>
                                                        <span className='plus15Coin'> {AddCoinFirstregister} <img src={coinplus} /></span>
                                                        <span className='PlusPlus'>
                                                            +
                                                        </span>
                                                        <span className='plus15XP'> {AddXpFirstregister} <img src={xpplus} /></span>
                                                    </>
                                                    :
                                                    <>
                                                        <span className='PlusPlus'>
                                                            +
                                                        </span>
                                                        <span className='plus15Coin'> {AddCoinFirstregister} <img src={coinplus} /></span>
                                                    </>

                                                :
                                                AddXpFirstregister > 0 ?
                                                    <>
                                                        <span className='PlusPlus'>
                                                            +
                                                        </span>
                                                        <span className='plus15XP'> {AddXpFirstregister} <img src={xpplus} /></span>
                                                    </>

                                                    : null
                                        }
                                        {/*  */}

                                    </div>
                                    <img src={AddAccount2} /> Add Account
                                </Link>
                                <div className='AutoJoinListButton2Container'>
                                    {AutoJoinStatus == true && CountJoining > 0 ?
                                        <div className='AccountListHeaderItem2' onClick={() => StopAllAccountJoin()}>
                                            <div className='StopListButton2' onClick={() => StopAllAccountJoin()}>
                                                <img src={JoinStop} className='JoinStopIcon' />     Stop
                                            </div>
                                        </div>

                                        :
                                        //  ShowStartAll ?
                                        <div className='AccountListHeaderItem2' onClick={() => MiniLoading && AccountCount <= 0 ? null : StartAllAccountJoin('All')}>
                                            {

                                                AccountCount > 0 ?
                                                    // MiniLoading ?
                                                    //     <div className="loadminiBlue"></div>
                                                    //     :
                                                    <button className='AutoJoinListButton2'>
                                                        <img src={AutoJoinList} /> AUTO JOIN
                                                    </button>
                                                    :
                                                    <button className='AutoJoinListButton2disable'>
                                                        <img src={AutoJoinDisableDark} /> AUTO JOIN
                                                    </button>


                                            }

                                        </div>
                                        // :

                                        // null

                                    }

                                </div>

                            </div>

                        </div>

                        {/* <Link to='/LoginTelegram' className='AddAccountAbsoluteButtonInner'>
                            <AddIcon className='AddIcon' />
                        </Link> */}

                        {/* </div>

                        </div> */}
                        <BottomSheet onDismiss={onDismiss} open={ShowLogOut}>
                            <div className='RegisterBeforeBottomSheet'>
                                <div className='RegisterBeforeBottomSheetHeader'>
                                    <div className='RegisterBeforeBottomSheetHeaderText1'>
                                        {/* <img src={DangerBottomSheet} className='DangerBottomSheet' /> */}
                                        Remove from the list
                                    </div>
                                </div>
                                <div className='RegisterBeforeBottomSheetBody'>

                                    Are you sure you want to remove <span className='UserNameChannelBold'>+{PhoneForLogout}</span> from the list?


                                </div>
                                <div className='RegisterBeforeBottomSheetFooter'>
                                    <button className='BottomSheetCancelButton' onClick={() => setShowLogOut(false)}>Cancel</button>
                                    <button className='BottomSheetLoginButton' onClick={() => ConfirmLogOut(IdForLogout)}>
                                        {
                                            LoadingForLogOut ? <div className="loadminijoin"></div>
                                                : "Remove"
                                        }

                                    </button>
                                </div>
                            </div>
                        </BottomSheet>
                        <BottomSheet onDismiss={onDismissNewUser} open={NewUser}>
                            <div className='NewUserBottomSheet'>
                                <div className='NewUserBottomSheetTitle'>
                                    Add account reward
                                </div>
                                <div className='NewUserBottomSheetLottie'>

                                </div>
                                <Lottie animationData={newuser} loop={true} style={{ "width": "125px", "height": "133px" }} />
                                <div className='NewUserBottomSheetReward'>
                                    {
                                        AddCoinFirstregister > 0 ?
                                            AddXpFirstregister > 0 ?
                                                <>
                                                    {AddCoinFirstregister} <img src={coinplus} className='NewUserBottomSheetIcon' />
                                                    +
                                                    {AddXpFirstregister} <img src={xpplus} className='NewUserBottomSheetIcon' />

                                                </>
                                                :
                                                <>
                                                    {AddCoinFirstregister} <img src={coinplus} className='NewUserBottomSheetIcon' />

                                                </>

                                            :
                                            AddXpFirstregister > 0 ?
                                                <>
                                                    {AddXpFirstregister} <img src={xpplus} className='NewUserBottomSheetIcon' />

                                                </>

                                                : null
                                    }

                                </div>
                                <div className='ParentNewUserBShButton'>
                                    <button className='NewUserBShButton' onClick={() => onDismissNewUser()}>
                                        Got it
                                    </button>
                                </div>
                            </div>


                        </BottomSheet>

                    </div>


            }
        </>
    )
}

export default AccountList
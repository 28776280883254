import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { setBottomSheetPriceAndChannel } from './../app/Home'

import { setBottomSheetCheckUsername } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import CoinBottomSheet from './../Public/Image/Home/CoinBottomSheet.svg'
import PersonIcon from '@mui/icons-material/Person';
// import { StoreChannelByCoin } from './../Api/StoreChannelByCoin'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { setBottomSheetTooManyOrders } from './../app/Home'
import { setBottomSheetViolatingChannel } from './../app/Home'
import { setInsufficientBalance } from './../app/Home'
import { setUserCoin } from './../app/Home'
import { AllApi } from './../Api/AllApi'
import {
    SetAppSettingData,
    SetProfileData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    
    SetUserReward,
   
    SetDataClaimReward,SetxpCountLeague,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState
} from './../app/Game'
const ShowPriceAndChannelCoin = () => {


    const dispatch = useDispatch()
    const BottomSheetPriceAndChannel = useSelector((state) => state.Home.BottomSheetPriceAndChannel)
    const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)
    const history = useHistory();

    function onDismiss() {
        setTimeout(() => {
        dispatch(setBottomSheetPriceAndChannel(false))
    }, 300);
    }
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const _id = searchParams.get('_id');
    // const sku = searchParams.get('sku');
    const price = parseInt(searchParams.get('Price'));
    const Count =parseInt(searchParams.get('Count'));
    const Email = useSelector((state) => state.counter.email)
    const Pay = async (_id, UserNameForOrder) => {

        // let res = await StoreChannelByCoin(_id, UserNameForOrder)

        const MakeApiClass = new AllApi();
        let res=await MakeApiClass.StoreChannelByCoin(_id, UserNameForOrder)
        console.log(res)
        if (res.code === 200) {
  dispatch(SetxpCountLeague(res.data.user.xpCountLeague))
            dispatch(setUserCoin(res.data.user.coins))

            history.push(`/Home?payment=${true}&username=${UserNameForOrder}&count=${Count}&point=${res.data.user.point}`);
        } else if (res.code === 304) {
            // too many orders

            dispatch(setBottomSheetTooManyOrders(true))
        }
        else if (res.code === 303) {
            dispatch(setBottomSheetViolatingChannel(true))
            // امکان عضوگیری برای این کانال وجود ندارد
        }
        else if (res.code === 302) {
            // سکه کافی برای ثبت کانال ندارید
            dispatch(setInsufficientBalance(true))

        }
        else if (res.code === 301) {
            // اگر بسته ممبر پبدانشد
        } else if (res.code === 300) {
            // Invalid value
        }else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }

        dispatch(setBottomSheetPriceAndChannel(false))

    }
    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetPriceAndChannel}>
            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Price</div>
                        <div className='ShowPriceAndChannelValue'>{price.toLocaleString()} <span className='CoinSign'><img src={CoinBottomSheet} /></span></div>
                    </div>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Count</div>
                        <div className='ShowPriceAndChannelValue'>{Count.toLocaleString()} <PersonIcon className='PersonIcon' /></div>
                    </div>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Username</div>
                        <div className='ShowPriceAndChannelValue'>{UserNameForOrder}</div>
                    </div>
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    {/* <button className='BottomSheetCancelButton' onClick={() => dispatch(setBottomSheetCheckUsername(false))}>Cancel</button> */}
                    <button className='BottomSheetShowPriceAndChannelButton' onClick={() => Pay(_id, UserNameForOrder)}>Pay</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default ShowPriceAndChannelCoin
import React from 'react'
import Person from './../Public/Image/Home/Person.svg'
import './MemberPackageItem.css'
// import { GetMember } from './../Api/GetMember'
import { GetCookie } from '../Action/GetCookie'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import { SetPackageData } from './../app/Home'
import { setUserCoin } from './../app/Home'
import { setLoading } from './../app/Home'
import { AllApi } from './../Api/AllApi'
import { useHistory } from "react-router-dom";
import EmptyPack from './../Public/Image/Home/EmptyPack.svg'
import xpplus from './../Public/Image/Join/xpplus.svg'

const MemberPackageItem = () => {
    const PackageData = useSelector((state) => state.Home.PackageData)
    const history = useHistory();
    const NoticeChecked = localStorage.getItem('Notice')
    const dispatch = useDispatch()
    const Countries = useSelector((state) => state.Home.Countries)
    // const Token = GetCookie('Token')
    const IsIranian = useSelector((state) => state.Home.IsIranian)

    const GetMemberFunc = async () => {
        dispatch(setLoading(true))
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.GetMember(false)
        // let res = { code: 999 }

        if (res.code == 200) {

            dispatch(setUserCoin(res.data.user.coins))
            dispatch(SetPackageData(res.data.data))
            dispatch(setLoading(false))


        }
        else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            dispatch(setLoading(false))

        }
        else if (res.code == 999) {
       

            dispatch(setLoading(false))

        }

    }
    useEffect(() => {
        if (PackageData.length === 0) {
            // GetMemberFunc()
        }
    }, [])
//   console.log(PackageData)

    return (

        <>
            {
                PackageData.length > 0 ?
                    PackageData.map(item =>
                        item.buyCountries.name === Countries ?
                            item.purchase_getmember.map((Memberitem, i) =>
                                <>
                                    {/* <div className='MemberPackageTitle'>
                        Get Member
                      </div> */}
                                    <Link to={NoticeChecked ?
                                        `/GetLinkUsername?Price=${Memberitem.price}&Count=${Memberitem.member_count}&_id=${Memberitem._id}&sku=${Memberitem.sku}&ByCoin=false` :
                                        `/Notice?Price=${Memberitem.price}&Count=${Memberitem.member_count}&_id=${Memberitem._id}&sku=${Memberitem.sku}&ByCoin=false`}
                                        className='MemberPackageItem' key={i}>
                                        <div className='MemberItem'>
                                            <div>
                                                <img src={Person} />
                                            </div>
                                            <div className='MemberItemDigit'>
                                                <div className='MemberItemDigitNewPrice'>
                                                    {Memberitem.member_count.toLocaleString()}
                                                    
                                                      {Memberitem.xpCount ?<span className='XpCountInlist'>+ {Memberitem.xpCount} <img src={xpplus} className='XpCountInlistIcon' /> </span>: null}  
                                                   
                                                    
                                                </div>
                                              
                                                {/* <div className='MemberItemDigitOldPrice'>
                                            {Memberitem.member_count_real.toLocaleString()}

                                            </div> */}
                                            </div>
                                        </div>
                                        <div className='MemberItem'>
                                            {Memberitem.discountString ?
                                                <div className='MemberItemOffDigit'>

                                                    {Memberitem.discountString}
                                                </div>
                                                :
                                                null
                                            }

                                            <div >
                                                <div className='MemberItemOrder'>
                                                    <button className='MemberItemOrderButton'>$ {Memberitem.price} </button>
                                                </div>
                                                {
                                                    Memberitem.discountString ?
                                                        <div className='MemberItemOrderOldPrice'>
                                                            {/* 10.900 $ */}
                                                            {/* $ {parseFloat((100 * Memberitem.price) / Memberitem.discount).toFixed(2)}  */}
                                                            {/* $  {Memberitem.price / (1 - Memberitem.discountDecimal)} */}
                                                            {/* $ {parseFloat((100 * Memberitem.price) / Memberitem.discount).toFixed(2)}  */}

                                                            $
                                                            {/* {parseFloat(Memberitem.price / (1 - Memberitem.discountDecimal/100)).toFixed(2)  */}

                                                            {parseFloat(Memberitem.discountweb).toFixed(2)
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                }

                                            </div>

                                        </div>


                                    </Link>
                                </>

                            )
                            : null

                    )


                    :
                    <div className='SomthingWentWrong'>

                        <img src={EmptyPack} />
                        <div className='SomthingWentWrongText'>
                            Something went wrong! Please try again.
                        </div>
                        <div>
                            <button className='SomthingWentWrongButton' onClick={() => GetMemberFunc()}>
                                Retry
                            </button>
                        </div>
                    </div>
            }

        </>

    )
}

export default MemberPackageItem
import React from 'react'
import { GameDB } from '../DataBase/GameDB'
import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState, SetTopJoinerTime,
    SetIsBetweenLeaguge, SetjoinLeagueEnable, SetAddXpFirstregister,
    SetAddCoinFirstregister, SetjoinXpRewards,
    SetjoinCoinRewards, SetleagueWithXp, SetXpMax, SetXpMin,SetverifyBotEnable

} from './../app/Game'
export class GetUserDataFromAppSettingGame extends React.Component {
    constructor() {
        super();
    }
    async GetUserDataFromAppSetting(LevelUser, dispatch) {
        let AppSetting = await GameDB.AppSetting.toArray()
//    console.log(AppSetting[0].AppSetting.verifyBotEnable)
   dispatch(SetleagueWithXp(AppSetting[0].AppSetting.leagueWithXp))
   dispatch(SetverifyBotEnable(AppSetting[0].AppSetting.verifyBotEnable))

        if (AppSetting.length >= 0) {
        
            dispatch(SetAppSettingData(AppSetting[0].AppSetting))
            dispatch(SetjoinLeagueEnable(AppSetting[0].AppSetting.joinLeagueEnable))
            if (AppSetting[0].AppSetting.ADD_XP_FIRSTRIGISTER) {
                dispatch(SetAddXpFirstregister(AppSetting[0].AppSetting.ADD_XP_FIRSTRIGISTER))
            }
            if (AppSetting[0].AppSetting.ADD_COIN_FIRSTRIGISTER) {
                dispatch(SetAddCoinFirstregister(AppSetting[0].AppSetting.ADD_COIN_FIRSTRIGISTER))
            }
            if (AppSetting[0].AppSetting.leagueWithXp) {
                // console.log(AppSetting[0].AppSetting.leagueWithXp)
                dispatch(SetleagueWithXp(AppSetting[0].AppSetting.leagueWithXp))
            }

            if (AppSetting[0].AppSetting.joinRewards) {
              
                localStorage.setItem("joinCoinRewards", AppSetting[0].AppSetting.joinRewards)
                dispatch(SetjoinCoinRewards(AppSetting[0].AppSetting.joinRewards))
            }



            if (AppSetting[0].AppSetting.joinXpRewards) {
              
                localStorage.setItem("joinXpRewards", AppSetting[0].AppSetting.joinXpRewards)

                dispatch(SetjoinXpRewards(AppSetting[0].AppSetting.joinXpRewards))
            }


            let RewardArray = AppSetting[0].AppSetting.leagues

            if (LevelUser != false) {
                RewardArray.map(item => {

                    if (item.leagueLevel == LevelUser) {

                        dispatch(SetRewardLeague(item))
                    }
                })
                dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

                let LeaguesArray = AppSetting[0].AppSetting.leagues
              
                LeaguesArray.map(item => {
                    if (item.leagueLevel == LevelUser) {
                        dispatch(SetMyLeagueRank(item.leagueName))
                        dispatch(SetXpMin(item.minXp))
                        dispatch(SetXpMax(item.maxXp))
                        dispatch(SetLeagueLimitJoinProgress(item.max))
                        dispatch(SetLeagueMinimumJoinProgress(item.min))

                        dispatch(SetMyLeagueRankNumber(item.leagueLevel))

                    }
                })
            }
        } else {
      
            const timer = setInterval(async () => {
                AppSetting = await GameDB.AppSetting.toArray()
               
                if (AppSetting.length == 1) {
                    clearInterval(timer);
                    this.GetUserDataFromAppSetting(LevelUser, dispatch)
                } else {
                    
                }

            }, 1000);

        }
    }


}

import React from 'react'
import Add from './../Public/Image/Join/Add.svg'
import TelegramAdd from './../Public/Image/Join/telegramadd.svg'
// import rafiki from './../Public/Image/Join/rafiki.svg'
import './JoinAddAccount.css'
import { Link } from 'react-router-dom';
import Animation from './../Public/Image/Join/1.json'
import Lottie from "lottie-react";
import xpplus from './../Public/Image/Join/xpplus.svg'
import coinplus from './../Public/Image/Join/coinplus.svg'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import Loading from './../Loading'

const AddAccount = () => {

    const [LoadingState, setLoadingState] = useState(true)

    const joinCoinRewards = useSelector((state) => state.Game.joinCoinRewards)
    const joinXpRewards = useSelector((state) => state.Game.joinXpRewards)

    const AddXpFirstregister = useSelector((state) => state.Game.AddXpFirstregister)
    const AddCoinFirstregister = useSelector((state) => state.Game.AddCoinFirstregister)
    useEffect(() => {
        if (joinCoinRewards > 0 || joinXpRewards > 0 || AddXpFirstregister > 0 || AddCoinFirstregister > 0) {
            setLoadingState(false)
        }
    }, [joinCoinRewards])

    return (
        <div className='AddAccountContainer'>
            <div>
                <Lottie animationData={Animation} loop={true} style={{ "width": "125px", "height": "133px" }} />

            </div>
            <div className='AddAccountTitle'>
                FREE Coins by Join
            </div>
            {
                LoadingState ?
                    <Loading />
                    :
                    <div className='AddAccountDes'>
                        <ul className='AddAccountDesTextItem1Ul'>
                            <li className='AddAccountDesTextItem1'>
                                Add Telegram account and get &nbsp;
                                {AddCoinFirstregister ? <>{AddCoinFirstregister} <img src={coinplus} className='CoinInAddAcount' /></> : null}
                                {AddCoinFirstregister > 0 && AddXpFirstregister > 0 ? `and` : null}
                                {AddXpFirstregister ? <>  {AddXpFirstregister}  <img src={xpplus} className='XpInAddAcount' /></> : null}

                            </li>
                            <li className='AddAccountDesTextItem1'>

                                {joinCoinRewards ? <> Earn {joinCoinRewards} <img src={coinplus} className='CoinInAddAcount' /></> : null}
                                {joinXpRewards > 0 && joinXpRewards > 0 ? `and` : null}
                                {joinXpRewards ? <>  {joinXpRewards}  <img src={xpplus} className='XpInAddAcount' /></> : null}
                                {joinCoinRewards || joinXpRewards ? <> per Join</> : null}

                            </li>
                            <li className='AddAccountDesTextItem1'>

                                {joinCoinRewards ? <> Earn up to {joinCoinRewards * 500} <img src={coinplus} className='CoinInAddAcount' /></> : null}
                                {joinXpRewards > 0 && joinXpRewards > 0 ? `and` : null}
                                {joinXpRewards ? <>  {joinXpRewards * 500}  <img src={xpplus} className='XpInAddAcount' />  </> : null}
                                {joinCoinRewards || joinXpRewards ? <> per Telegram account</> : null}
                            </li>
                            <li className='AddAccountDesTextItem1'>
                                Start  <strong >Event </strong> with <strong >Join</strong> and get prize
                            </li>
                        </ul>
                    </div>
            }

            <div className='AddAccountButtonContainer'>
                <Link to="/LoginTelegram" className='AddAccountButton' >
                    <img alt='add' src={TelegramAdd} className='AddAccountButtonImg' />   Add Telegram account
                </Link>
            </div>

        </div>
    )
}

export default AddAccount
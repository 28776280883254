import React from 'react'
import Carousel from '../Carousel/Carousel'
import './Intro.css'
const Intro = () => {
  return (
    <div className='Intro'>
   
           <Carousel />
     
   
     
    </div>
  )
}

export default Intro
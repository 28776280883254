import React from 'react'
import Coin from './../Public/Image/Join/Coin.svg'
import CoinBig from './../Public/Image/Home/Coin.svg'

import { useEffect, useState } from 'react'
import './CoinHeader.css'
import { useSelector, useDispatch } from 'react-redux'
// import { GetProfile } from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from '../Action/GetCookie'
import { setUserCoin } from './../app/Home'
import { SetEmail } from './../app/Slice'
import { db } from './../DataBase/db'
import { useHistory } from 'react-router-dom';
import { TelegramDB } from './../DataBase/TelegramDB'
import { Link } from 'react-router-dom';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HeadImg from './../../src/Public/Image/Game/Group 26086606.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { GameDB } from '../DataBase/GameDB'
import xpplus from './../Public/Image/Join/xpplus.svg'
import Star from './../Public/Image/Join/Star 5.svg'


const CoinHeaderForManualJoin = () => {
    const dispatch = useDispatch()
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const history = useHistory();

    const UserCoin = useSelector((state) => state.Home.UserCoin)
    const Token = GetCookie('Token');
    const [CoinLocal, setCoinLocal] = useState('')

    const [PointDialogState, setPointDialogState] = useState('')
    const [xpCount, setxpCount] = useState('')
    const [xpLevel, setxpLevel] = useState('')
    const [xpLimit, setxpLimit] = useState('')
    const xpCountLeague = useSelector((state) => state.Game.xpCountLeague)

    const GetProfileFunction = async () => {
        const MakeApiClass = new AllApi();








        let res = await MakeApiClass.GetProfile(Token)
        // let res = await GetProfile(Token)
        let AppSetting = await GameDB.AppSetting.toArray()

        setPointDialogState(AppSetting[0].AppSetting.PointDialog)


        setxpCount(res.data.user.xpCount)
        setxpLevel(res.data.user.xpLevel)
        setxpLimit(res.data.user.xpLimit)

        if (res.code == 200) {
            dispatch(setUserCoin(res.data.user.coins))
            setCoinLocal(res.data.user.coins)
            let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })
            dispatch(SetEmail(res.data.user.email))

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }

    }

    useEffect(() => {


        GetProfileFunction()
    }, [UserCoin])




    const OpenBottomSheetShowXpDetails = () => {
        setBottomSheetState(true)
    }
    const [BottomSheetState, setBottomSheetState] = useState(false)
    function onDismiss() {
        setTimeout(() => {
            setBottomSheetState(false)
        }, 300);

    }
    function abbreviate_number(num) {
        if (num === null || num === undefined || num === false) return null;
        if (num === 0) return '0';
    
        let k = Math.floor((Math.log10(num)) / 3); // تعیین مقیاس (K, M, B, T)
        let scale = Math.pow(10, k * 3); // مقدار مقیاس برای تقسیم عدد
        let shortened = num / scale; // عدد را مقیاس‌بندی می‌کنیم
    
        return shortened.toFixed(1).replace(/\.0$/, '') + ['', 'K', 'M', 'B', 'T'][k]; // حذف `.0` از اعداد صحیح
    }
    
    return (
        <div className='CoinHeader' style={{ 'maxWidth': '769px', 'margin': 'auto', "marginTop": "16px", "marginBottom": "16px" }}>
            <div className='HeaderLogo' onClick={() => OpenBottomSheetShowXpDetails()} >
                {/* Coin */}
                <div className='HeaderLogoPtLevel' >

                    {/* <div className='HeaderProgressBarImgContainer'> */}
                    {/* <div className='HeaderProgressBarAbsoluteNumber'>
                            {xpLevel}

                        </div> */}
                    {/* <img src={HeadImg} className='HeaderProgressBarImg' /> */}
                    {/* </div> */}

                </div>
                <div className='HeaderLogoPt'>
                    {/* <div> */}
                    <img src={Star} className='HeaderProgressBarImg' />

                    <span style={{ 'fontWeight': '700', fontSize: "12px", }}>
                        {/* {abbreviate_number(xpLimit - xpCount)} */}
                        {xpCountLeague > 0 ? abbreviate_number(xpCountLeague) : 0}
                    </span>
                    {/* {abbreviate_number(xpLimit - xpCount)} Pts */}
                    {/* { xpCountLeague > 0 ? abbreviate_number(xpCountLeague) : 0} */}

                    {/* </div> */}
                    {/* <div>
                    To level {xpLevel ? xpLevel + 1 : null}
                    </div> */}
                </div>
            </div>
            {/* <Link to="/Coin?Status=Join" className='HeaderCoin' > */}
            {/* <AddCircleRoundedIcon className='AddCoinIcon' /> */}
            <div className='HeaderCoin'>
                <div>
                    {UserCoin.toLocaleString()}
                </div>
                <div className='HeaderCoinImgContainer'>
                    {
                        CountJoining > 0 ?
                            <>
                                <div className='CoinHeaderImageLoader'></div>
                                <img src={Coin} className='HeaderCoinImg1' />
                            </>

                            :
                            <img src={Coin} className='HeaderCoinImg1' />

                    }

                </div>
            </div>
            <BottomSheet onDismiss={onDismiss} open={BottomSheetState}>
            <div className='RegisterBeforeBottomSheet'>
          <div className='RegisterBeforeBottomSheetHeader'>
            <div className='HeaderLogoBottomSheet' >
             {xpCountLeague == 0 || xpCountLeague == 1? 
             "Point"
             :
             "Points"
             }  in this event: 
              <b style={{margin:"0 4px 0 4px"}}>
                { xpCountLeague>0 ?xpCountLeague.toLocaleString():0}
                </b>
              <img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px" }} />

            </div>
         
          </div> 
            <div className='HeaderLogoBottomSheetText' >
            How can we get Points?

            </div>
          <ul className='BottomSheetUlXpUl'>
            {
              PointDialogState ?
                PointDialogState.map((item, i) =>
                  <li key={i} style={{ "marginTop": "8px" }}>{item}</li>
                )
                : null
            }

          </ul>

          <div className='RegisterBeforeBottomSheetFooter'> 
               <Link to="/Profile" className='BottomSheetCancelButton'  >Show All pts</Link>
            <button  className="BottomSheetLoginButton" onClick={() => setBottomSheetState(false)}>Got it</button>
        
          </div>
        </div>
            </BottomSheet>
            {/* </Link> */}
        </div>
    )
}

export default CoinHeaderForManualJoin
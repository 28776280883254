import React from 'react'
import { useEffect } from 'react';

import { AllApi } from './../Api/AllApi'
import { TelegramDB } from './../DataBase/TelegramDB'
import { GetCookie } from '../Action/GetCookie'
import { addOrUpdateAutoJoinAccount } from "./../app/JoinState";

// import {
//     SetAccount, SetActiveAccount, SetRestrictAccount, SetActiveAccountNumber, SetAccountJoining
// } from './../app/JoinAction'

export class CheckDataBaseJoin extends React.Component {
    constructor() {
        super();
        this.Token = GetCookie('Token')

    }
    //  CheckDataBase 
    async CheckTelegramData(dispatch) {
        let TelegramAccount = await TelegramDB.Data.toArray()
        // dispatch(SetAccount(TelegramAccount))
        return TelegramAccount
    }
    // CheckAccounts
    async CheckAccounts(dispatch) {
        let ActiveAccount = [];
        let arr = [];
        let RestrictAccount = [];

        let res = await this.CheckTelegramData(dispatch);

        // فیلتر کردن حساب‌های فعال
        ActiveAccount = res.filter(item => item.Session !== false && item.BotSession !== "");
        // dispatch(SetActiveAccount(ActiveAccount))
        // پردازش حساب‌هایی که AutoJoin دارند
        res.forEach(item => {
            if ((item.AutoJoin === true|| item.AutoJoinSingle=== true) && item.Session !== false && item.BotSession !== false ) {


                if (item.FloodWait > 0 || item.CHANNELS_TOO_MUCH === true || item.TooMuchLeft === true) {
                    RestrictAccount.push(item.id);
                } else {
                    // setAutoJoinStatus(true);
                    dispatch(addOrUpdateAutoJoinAccount({ id: item.id, AutoJoin: false }));

                    arr.push(item.id);
                }
            } else {
                dispatch(addOrUpdateAutoJoinAccount({ id: item.id, AutoJoin: true }));

            }
        });

        // بروزرسانی وضعیت‌ها

        // dispatch(SetActiveAccountNumber(ActiveAccount.length))
        // dispatch(SetAccountJoining(arr.length))
        // dispatch(SetRestrictAccount(RestrictAccount))




    }



}

import { API_ID, API_HASH } from './../Const';
import { StringSession } from 'telegram/sessions';
import { TelegramClient } from 'telegram';
import { TelegramDB } from './../DataBase/TelegramDB';

export async function CheckConnection(Session, id) {
    if (!Session) {
        console.error("❌ Session is missing!");
        return { status: false };
    }

    let MyClient;

    async function connectWithTimeout(Session, id) {
        let res;
        const stringSession = new StringSession(Session);
        const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
            connectionRetries: 5,
            useIPV6: true,
            langCode: 'en',
            systemLangCode: 'en',
        });

        let JoinTimeout;

        const connectPromise = new Promise(async (resolve, reject) => {
            try {
                await Client.connect();
                MyClient = Client;
                // console.log("✅ Connected:", Client);

                const isAuthorized = await Client.isUserAuthorized();
                // console.log("🔑 User Authorized:", isAuthorized);
                if (!isAuthorized) {
                    res = isAuthorized;
                    reject("User is not authorized (Invalid Session)");
                }

                const user = await Client.getMe();
                // console.log("👤 User Info:", user);
                if (!user) {
                    res = user;
                    reject("Failed to fetch user data");
                }

                resolve(Client);
            } catch (error) {
                res = error;
                reject(error);
            }
        });

        const timeoutPromise = new Promise((_, reject) => {
            JoinTimeout = setTimeout(() => {
                reject(new Error('❌ Connection timed out after 70 seconds'));
            }, 70000);
        });

        try {
            const result = await Promise.race([connectPromise, timeoutPromise]);
            clearTimeout(JoinTimeout);
            res = result;
        } catch (error) {
            console.error("⚠ Error during connection:", error);
            if (
                error.errorMessage === "AUTH_KEY_DUPLICATED" ||
                error.errorMessage === "USER_DEACTIVATED_BAN" ||
                error.errorMessage === "SESSION_REVOKED" ||
                error.errorMessage === "AUTH_KEY_UNREGISTERED"
            ) {
                console.warn("❌ Session is invalid, removing from database...");
                await TelegramDB.Data.where({ id: Number(id) }).modify({ Session: false });
                return { status: 'logout', Client: false };
            }
            res = error;
        }
        return res;
    }

    try {
        const connectRes = await connectWithTimeout(Session, id);

        if (!connectRes || connectRes.code === 401) {
            console.error("❌ Connection failed: Invalid Session");
            return { status: false };
        } else {
            console.log("✅ Connection successful:", MyClient);
            return { status: true, Client: MyClient };
        }
    } catch (error) {
        console.error("⚠ Final Error:", error);
        if (
            error.errorMessage === "AUTH_KEY_DUPLICATED" ||
            error.errorMessage === "USER_DEACTIVATED_BAN" ||
            error.errorMessage === "SESSION_REVOKED" ||
            error.errorMessage === "AUTH_KEY_UNREGISTERED"
        ) {
            console.warn("❌ Removing invalid session from database...");
            await TelegramDB.Data.where({ id: Number(id) }).modify({ Session: false });
            return { status: 'logout', Client: false };
        }
    }
}

// // import React from 'react'
// import { API_ID, API_HASH } from './../Const'
// import { StringSession } from 'telegram/sessions'
// import { TelegramClient } from 'telegram'
// import { TelegramDB } from './../DataBase/TelegramDB'

// export async function CheckConnection(Session, id) {
//     // console.log(Session)
//     // console.log(id) 
//     let MyClient
//     // async function connectWithTimeout(Session, id) {
//     //     // console.log(id)  

//     //     const stringSession = new StringSession(Session);
//     //     // const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
//     //     //     connectionRetries: 1,reconnect: true
//     //     // });

//     //     const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
//     //         connectionRetries: 2,
//     //         useIPV6: true,
//     //         langCode: 'en',
//     //         systemLangCode: 'en',
//     //         // proxy: {
//     //         //     ip: "45.89.19.41",
//     //         //     port: 7711,
//     //         //     username: "FFhf0l",
//     //         //     password: "bBlM1c6JSG",
//     //         //     MTProxy: false,
//     //         //     socksType: 5,
//     //         //     timeout: 20,
//     //         // }
//     //     })


        
//     //     let JoinTimeout

//     //     const connectPromise = new Promise((resolve, reject) => {
//     //         resolve(Client.connect());
//     //         clearTimeout(JoinTimeout)
//     //     });


//     //     const timeoutPromise = new Promise((resolve, reject) => {
//     //         JoinTimeout = setTimeout(reject, 50000, 'errorConnection');
//     //     });


//     //     Client.setLogLevel("none");
//     //     // const connectPromise = await Client.connect();
//     //     // console.log(connectPromise)
//     //     MyClient = Client
//     //     // await Client.disconnect()
//     //     // const timeoutPromise = new Promise((resolve, reject) => {
//     //     //     // Reject the promise after 5 seconds
//     //     //     setTimeout(() => {
//     //     //         reject(('Connection timed out after 5 seconds'));
//     //     //         // setConnection(true)
//     //     //         Client._disconnect()
//     //     //         // MyClient.disconnect()
//     //     //         // Client.destroy();

//     //     // // console.log(Client.disconnected())


//     //     //     }, 10000);
//     //     // });
//     //     let res
//     //     try {
//     //         res = await Promise.race([connectPromise, timeoutPromise, Client]);
//     //         // console.log(res)
//     //         // console.log(Client)
//     //     } catch (error) {
//     //         // console.log(error)
//     //         res = error

//     //     }

//     //     // console.log(Client.disconnected())

//     //     return res
//     // }
//     async function connectWithTimeout(Session, id) {
//         // console.log(Session)
//         let res
//         if(!Session){return null}
//         const stringSession = new StringSession(Session);
//         const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
//             connectionRetries: 20,
//             useIPV6: true,
//             langCode: 'en',
//             systemLangCode: 'en',
//         });
    
//         let JoinTimeout;
    
//         const connectPromise = new Promise(async (resolve, reject) => {
//             try {
//                 await Client.connect();
//                 MyClient = Client
//                 console.log(Client) 
//                 // بررسی اینکه آیا واقعا اتصال برقرار شده است
//                 const isAuthorized = await Client.isUserAuthorized();
//                 console.log(isAuthorized)  
//                 // if (!isAuthorized) throw new Error("User is not authorized (Invalid Session)");
//                 if (!isAuthorized) {res=isAuthorized};
     
//                 // تست یک درخواست واقعی برای بررسی اتصال صحیح
//                 const user = await Client.getMe();
//                 console.log(user)
//                 // if (!user) throw new Error("Failed to fetch user data");
//                 if (!user) {res=user};
//                 console.log(Client) 
//                 resolve(Client); // اگر همه چیز درست بود، کلاینت را برمی‌گردانیم
//             } catch (error) {
//                 // console.log(error)
//             res= error;

//                 reject(error); // در صورت بروز مشکل، خطا را ارسال می‌کنیم
//             }
//             return res
//         });
    
//         const timeoutPromise = new Promise((_, reject) => {
//             JoinTimeout = setTimeout(() => {
//                 reject(new Error('Connection timed out after 115 seconds'));
//             }, 71000);
//         });
    
//         try {
//             const result = await Promise.race([connectPromise, timeoutPromise]);
//             clearTimeout(JoinTimeout); // اگر متصل شد، تایمر را متوقف کن
//         //    console.log(result) 
//             res= result;
//         } catch (error) {
//             // console.log(error)
//             if (error.errorMessage == "AUTH_KEY_DUPLICATED" || error.errorMessage == "USER_DEACTIVATED_BAN" || error.errorMessage == 'SESSION_REVOKED' || error.errorMessage == 'AUTH_KEY_UNREGISTERED') {
//                 // const id = await TelegramDB.Data.delete(ItemID);
//                 let res = await TelegramDB.Data.where({ id: Number(id) }).modify({ Session: false })
//                 // console.log(ItemID)
//                 // history.push(`/Coin`); 
//                 return { status: 'logout', Client: false }
//             }
//             res= error;
//         }
//         return res
//     }
    

//     // console.log(Session)

//     try {
//         //     const timeoutPromise1 = new Promise((_, reject) =>
//         //     setTimeout(() => reject(new Error('Operation timed out')), 4000)
//         //   );
//         // ResConnection1.Client.disconnect();

//         const connectRes = await connectWithTimeout(Session, id);
//         // let connectRes
//         // let ConTimeout;

//         // const myPromise1 = new Promise((resolve, reject) => {
//         //     resolve(connectWithTimeout(Session, id));
//         //     clearTimeout(ConTimeout)
//         // });


//         // const myPromise2 = new Promise((resolve, reject) => {
//         //     ConTimeout = setTimeout(resolve, 7000, "errorConnection");

//         // });


//         // connectRes = await Promise.race([myPromise1, myPromise2]).then((x) => {
//         //     return x
//         // });
//         console.log(connectRes)
//         if (connectRes == false || connectRes==null || connectRes.code == 401) {
//             return { status: false }

//         } else {
//             console.log(MyClient) 

//             return { status: true, Client: MyClient }
//         }


//     } catch (error) {
//         // console.log('Connection failed:', error);
//         // console.log(error)
//         if (error.errorMessage == "AUTH_KEY_DUPLICATED" || error.errorMessage == "USER_DEACTIVATED_BAN" || error.errorMessage == 'SESSION_REVOKED' || error.errorMessage == 'AUTH_KEY_UNREGISTERED') {
//             // const id = await TelegramDB.Data.delete(ItemID);
//             let res = await TelegramDB.Data.where({ id: Number(id) }).modify({ Session: false })
//             // console.log(ItemID)
//             // history.push(`/Coin`); 
//             return { status: 'logout', Client: false }
//         }

//     }
//     // setTimeout(() => {
//     //     return { status: false }

//     // }, 5000);


// }

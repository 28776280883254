import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { SetCongratulationsBottomSheet } from './../../app/Game'
import './Congratulations.css'
import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import { useSelector, useDispatch } from 'react-redux'
import Animation from './../../Public/Animation/Comp 1.json'
import Lottie from "lottie-react";
import { SetClaimRewardCall } from './../../app/Game'

const Congratulations = () => {
    const dispatch = useDispatch()
    const CongratulationsBottomSheet = useSelector((state) => state.Game.CongratulationsBottomSheet)
    const GiftTopjoin = useSelector((state) => state.Game.GiftTopjoin)
    const leagueWithXp = useSelector((state) => state.Game.leagueWithXp)

    function onDismiss() {
        setTimeout(() => {
            // dispatch(SetCongratulationsBottomSheet(false))
            CloseBottomSheet()
        }, 300);

    }

    function abbreviate_number(num) {
        if (num === null || num === undefined || num === false) return null;
        if (num === 0) return '0';
    
        let k = Math.floor((Math.log10(num)) / 3); // تعیین مقیاس (K, M, B, T)
        let scale = Math.pow(10, k * 3); // مقدار مقیاس برای تقسیم عدد
        let shortened = num / scale; // عدد را مقیاس‌بندی می‌کنیم
    
        return shortened.toFixed(1).replace(/\.0$/, '') + ['', 'K', 'M', 'B', 'T'][k]; // حذف `.0` از اعداد صحیح
    }
    
    const CloseBottomSheet = () => {

        setTimeout(() => {
            dispatch(SetCongratulationsBottomSheet(false))
        }, 300);
        let curTime = Date.now();

        // localStorage.setItem('CloseCongratulations', curTime)
        dispatch(SetClaimRewardCall(true))
    }
    useEffect(() => {
        const timer = setInterval(async () => {

          
            if (GiftTopjoin > 0) {

                clearInterval(timer);

            } else {
           
            }

        }, 1000);
    }, [GiftTopjoin])

    return (
        GiftTopjoin[0] ?
            <BottomSheet onDismiss={onDismiss}
                open={CongratulationsBottomSheet}
                // open={true}

            >
                {/* leagueWithXp */}
                <div className='CongratulationsBottomSheet'>
                    <div className='CongratulationsBottomSheetHeader'>
                        <div className='CongratulationsBottomSheetHeaderText'>🎉 Congratulations 🎉</div>
                    </div>
                    <div className='CongratulationsBottomSheetBody'>
                        {/* <img src={One} /> */}
                        <Lottie animationData={Animation} loop={false} style={{ "width": "120px", "height": "120px" }} />

                        <div className='CongratulationsBottomSheetBodyReward'>
                            Your Rewards:
                        </div>
                        <div className='CongratulationsBottomSheetBodyRewardNumber'>
                            {GiftTopjoin[0].coin ? abbreviate_number(GiftTopjoin[0].coin) : 0} <img src={Coin} className='CongratulationsBottomSheetBodyRewardCoin' />
                            {leagueWithXp ? 
                            
                            null
                            : 
                            (
                                GiftTopjoin[0]?.xp ? (
                                    <>
                                    +    {abbreviate_number(GiftTopjoin[0].xp)}
                                        <img src={Star} className="CongratulationsBottomSheetBodyRewardStar" alt="star" />
                                    </>
                                ) : (
                                    <>0</>
                                )
                            ) 
                            
                            
                            }
                        </div>
                    </div>
                    <div className='CongratulationsBottomSheetFooter'>

                        <button className='CongratulationsBottomSheetButton' onClick={() => CloseBottomSheet()} >Confirm</button>
                    </div>
                </div>
            </BottomSheet>
            : null

    )
}

export default Congratulations 
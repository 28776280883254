import * as React from 'react';

import Dialog from '@mui/material/Dialog';

import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import "./LoginTelegramDialoge.css"
import Animation from './../Public/Image/Join/1.json'
import Lottie from "lottie-react";
// import { Link } from 'react-router-dom';
import TelegramAdd from './../Public/Image/Join/telegramadd.svg'
import xpplus from './../Public/Image/Join/xpplus.svg'
import coinplus from './../Public/Image/Join/coinplus.svg'
import { useSelector, useDispatch } from 'react-redux'
// import { useHistory } from "react-router-dom";
import { SetLoginTelegramDialogeState } from '../app/Home'
// import { useSelector, useDispatch } from 'react-redux'
// import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginTelegramDialoge() {
    const AddXpFirstregister = useSelector((state) => state.Game.AddXpFirstregister)
    const AddCoinFirstregister = useSelector((state) => state.Game.AddCoinFirstregister)
    const LoginTelegramDialogeState = useSelector((state) => state.Home.LoginTelegramDialogeState)
    const dispatch = useDispatch()



    const handleClose = () => {
        dispatch(SetLoginTelegramDialogeState(false))

    };
    const handleGoToProfile = () => {

        // localStorage.setItem("LoginTelegramDialoge", 3)

        dispatch(SetLoginTelegramDialogeState(false))
        window.location.href = "/LoginTelegram";

        //    navigate("/target-route"); // هدایت به روت هدف

        // history.push("/LoginTelegram"); // به مسیر /profile هدایت می‌شویم
    };

    return (

        <React.Fragment>

            <Dialog
                fullScreen
                open={LoginTelegramDialogeState}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <div className='DialogeContainer'>
                    <div className='HeaderDialoge'>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon sx={{ "color": "#000" }} />
                        </IconButton>
                    </div>
                    <div className='DialogeBody'>
                        <div>
                            <Lottie animationData={Animation} loop={true} style={{ "width": "125px", "height": "133px" }} />
                        </div>
                        <div style={{ "marginTop": "24px", "marginBottom": "16px", fontSize: "24px", fontWeight: "700" }}>
                            Get  <span style={{ "marginTop": "24px", "marginBottom": "16px", fontSize: "36px", fontWeight: "700" }}>
                                {AddCoinFirstregister ? <>  {AddCoinFirstregister} <img src={coinplus} className='CoinInAddAcount' style={{ width: "32px", height: "32px" }} /></> : null}
                                {AddCoinFirstregister > 0 && AddXpFirstregister > 0 ? `and` : null}
                                {AddXpFirstregister ? <>   {AddXpFirstregister}  <img src={xpplus} className='XpInAddAcount' /></> : null}

                            </span>
                            Gift
                        </div>
                        <div style={{ "marginTop": "24px", "marginBottom": "16px", fontSize: "16px", fontWeight: "400", color: "#212121" }}>
                            Just by adding a Telegram account!
                        </div>

                        <div className='AddAccountButtonContainer'>
                            {/* <Link to="/LoginTelegram" className='AddAccountButton' > */}
                            <div className='AddAccountButton' onClick={() => handleGoToProfile()}>
                                <img alt='add' src={TelegramAdd} className='AddAccountButtonImg' />
                                Add Telegram account
                            </div>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>


            </Dialog>


        </React.Fragment>
    );
}
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { API_ID, API_HASH } from './../Const'
import JoinBotImage from './../Public/Image/joinbot/JoinBot.svg'
import { UpdateStateTelegram, SaveSession } from './../app/Slice'
import './JoinBot.css'
// import { FirstRegister } from './../JoinBotApi/FirstRegister'
// import { TokenRegister } from './../JoinBotApi/TokenRegister'
import cryptlib from "cryptlib"
import Snackbar from '@mui/material/Snackbar';
import { TelegramDB } from './../DataBase/TelegramDB'
import { useHistory } from 'react-router-dom';
import { setMiniLoading } from './../app/Home'
import { Link } from 'react-router-dom';
import { AllApi } from './../Api/AllApi'
// import { message } from 'telegram/client'
import { SetCookie } from './../Action/SetCookie'


import { GetCookie } from './../Action/GetCookie'
const JoinBot = () => {
    const history = useHistory();
    const VerifyBotEnable = useSelector((state) => state.Game.verifyBotEnable)

    const Session = useSelector((state) => state.counter.Session)
    const dispatch = useDispatch()
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)

    let userv = JSON.parse(localStorage.getItem('user'))
    const PhoneNumber = userv.phone
    useEffect(() => {
        // console.log(VerifyBotEnable)
        if (!Session) {

            dispatch(SaveSession(JSON.parse(localStorage.getItem('session'))))
            // GetFullUserFunc()
        } else {

            // GetFullUserFunc()

        }
    }, [])

    const StartBotStringFunction = async (Client, userv) => {
        let res
        try {
            const result1 = await Client.invoke(
                new Api.messages.StartBot({
                    bot: "Verify_mem_bot",
                    peer: userv.username ? userv.username : userv.id,
                    startParam: 'hello',
                })
            );
            res = result1
            return res
        } catch (error) {
            res = error
            return 'error'
        }


    }


    const GetUpdatedTelegramAccont = async () => {
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        // setTelegramAccount(TelegramAccountUpdate)
        return TelegramAccountUpdate
    }

    const JoinBotFunction = async () => {
        dispatch(setMiniLoading(true))
        // console.log(VerifyBotEnable)
        if (VerifyBotEnable) {
            let userv = JSON.parse(localStorage.getItem('user'))

            const MyID = userv.id;
            let encryptedText
            const iv = '#GV^%^SUR&T*#Y*4'
            const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
            const user = {
                data: {
                    phonenumber: userv.phone,
                    tg_id: Number(MyID),
                    market: 'WebAppP'
                }
            }

            encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
            setShowLinkBack(false)


            // let res = await FirstRegister(encryptedText)
            const MakeApiClass = new AllApi();
            let res = await MakeApiClass.FirstRegister(encryptedText)

            if (res.status == 'success') {

                if (res.data.added_coin > 0 || res.data.added_xp > 0) {
                    localStorage.setItem('NewUser', true)
                }
                const Session = JSON.parse(localStorage.getItem('session'))

                const stringSession = new StringSession(Session);
                const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
                    connectionRetries: 3,
                });
                await Client.connect();
                const Me = await Client.getMe()



                let StartBotString = await StartBotStringFunction(Client, userv)

                if (StartBotString != 'error') {
                    try {


                        let chat = StartBotString.users[1]


                        let ResponseCode = await Client.sendFile('Verify_mem_bot', {
                            file: new Api.InputMediaContact({
                                phoneNumber: userv.phone,
                                firstName: userv.firstName,
                                lastName: userv.lastName ? userv.lastName : '',
                                vcard: `BEGIN:VCARD\nVERSION:3.0\nFN:mas\nTEL;MOBILE:${userv.phone}\nEND:VCARD`
                            })
                        }
                        )


                        //  const msgs = await Client.getMessages(chat, {
                        //                 limit: 1,
                        //             });
                        // let ResponseCode = true
                        if (ResponseCode) {
                            // const messages = await Client.getMessages('Verify_mem_bot', { limit: 1 })
                            let messages = await Client.getMessages('Verify_mem_bot', { limit: 1 })


                            if (messages) {
                                let j = 0
                                let i = setInterval(async () => {
                                    if (j < 5) {

                                        messages = await Client.getMessages('Verify_mem_bot', { limit: 1 })
                                        if (messages[0].message.includes('verify code membersgram')) {


                                            var thenum = messages[0].message.replace(/^\D+/g, ''); // Replace all leading non-digits with nothing
                                            thenum = messages[0].message.match(/\d+/)[0] // "3"


                                            const iv = '#GV^%^SUR&T*#Y*4'
                                            const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
                                            const user = {
                                                data: {
                                                    phonenumber: userv.phone,
                                                    verifyCode: thenum
                                                }
                                            }

                                            let encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
                                            const MakeApiClass = new AllApi();
                                            let TokenRegistervar = await MakeApiClass.TokenRegister(encryptedText)
                                            if (TokenRegistervar.code == 200) {

                                                localStorage.setItem('BotSession', JSON.stringify(TokenRegistervar.data.data.token))
                                                AddTelegramSession()


                                                let SaveRes = GetCookie('SaveAaccount')

                                                // if (SaveRes == 'true') {
                                                SetCookie('SaveAaccount', false, 100)
                                                let res = await GetUpdatedTelegramAccont()

                                                res.map(async (item) => {
                                                    if (item.PhoneNumber == userv.phone) {
                                                        const MakeApiClass = new AllApi();
                                                        let SessionsAccountRes = await MakeApiClass.SessionsAccount(item.Session, TokenRegistervar.data.data.token, item.User, SaveRes)


                                                    }
                                                })


                                                // } else {

                                                // }
                                                history.push(`/Coin?Status=Join`);
                                                localStorage.removeItem('BotSession')
                                                localStorage.removeItem('data')
                                                localStorage.removeItem('session')
                                                // localStorage.removeItem('user')


                                                clearInterval(i);
                                            } else if (TokenRegistervar.code == 120 || TokenRegistervar.code == 121 || TokenRegistervar.code == 122 || TokenRegistervar.code == 123) {
                                                let name = "Token"
                                                let name1 = "active"
                                                document.cookie = name + '=; Max-Age=-99999999;';
                                                document.cookie = name1 + '=; Max-Age=-99999999;';
                                                history.push("/");
                                                // dispatch(setLoading(false))

                                            }

                                        }
                                    } else {

                                        dispatch(setMiniLoading(false))
                                        handleClick({ vertical: 'bottom', horizontal: 'center' })
                                        setShowLinkBack(true)
                                        clearInterval(i);
                                    }

                                    j++
                                }, 1000);


                            }



                        }
                    } catch (error) {

                        dispatch(setMiniLoading(false))
                        handleClick({ vertical: 'bottom', horizontal: 'center' })
                        setShowLinkBack(true)
                    }

                } else {

                    dispatch(setMiniLoading(false))
                    handleClick({ vertical: 'bottom', horizontal: 'center' })
                    setShowLinkBack(true)
                }



                // }
            } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))

            } else {
                handleClick({ vertical: 'bottom', horizontal: 'center' })
                setShowLinkBack(true)
            }
            dispatch(setMiniLoading(false))
        } else {
            let userv = JSON.parse(localStorage.getItem('user'))

            const MyID = userv.id;
            let encryptedText
            const iv = '#GV^%^SUR&T*#Y*4'
            const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
            const user = {
                data: {
                    phonenumber: userv.phone,
                    tg_id: Number(MyID),
                    market: 'WebAppP'
                }
            }

            encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
            setShowLinkBack(false)

            // console.log(userv)
            // let res = await FirstRegister(encryptedText)
            const MakeApiClass = new AllApi();
            let res = await MakeApiClass.FirstRegister(encryptedText)
            // console.log(res)

            if (res.status == 'success') {

                if (res.data.added_coin > 0 || res.data.added_xp > 0) {
                    localStorage.setItem('NewUser', true)
                }

                try {



                    const iv = '#GV^%^SUR&T*#Y*4'
                    const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
                    const user = {
                        data: {
                            phonenumber: userv.phone,
                            verifyCode: 12345
                        }
                    }
                    // console.log(user)
                    let encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
                    const MakeApiClass = new AllApi();
                    let TokenRegistervar = await MakeApiClass.TokenRegister(encryptedText)
                    console.log(TokenRegistervar)

                    if (TokenRegistervar.code == 200) {

                        localStorage.setItem('BotSession', JSON.stringify(TokenRegistervar.data.data.token))
                        AddTelegramSession()


                        let SaveRes = GetCookie('SaveAaccount')
                        console.log(SaveRes)

                        SetCookie('SaveAaccount', false, 100)
                        let res = await GetUpdatedTelegramAccont()
                        console.log(res)

                        res.map(async (item) => {
                            if (item.PhoneNumber == userv.phone) {
                                const MakeApiClass = new AllApi();
                                let SessionsAccountRes = await MakeApiClass.SessionsAccount(item.Session, TokenRegistervar.data.data.token, item.User, SaveRes)


                            }
                        })
                        console.log('res')

                        history.push(`/Coin?Status=Join`);
                        localStorage.removeItem('BotSession')
                        localStorage.removeItem('data')
                        localStorage.removeItem('session')

                    } else if (TokenRegistervar.code == 120 || TokenRegistervar.code == 121 || TokenRegistervar.code == 122 || TokenRegistervar.code == 123) {
                        let name = "Token"
                        let name1 = "active"
                        document.cookie = name + '=; Max-Age=-99999999;';
                        document.cookie = name1 + '=; Max-Age=-99999999;';
                        history.push("/");


                    }


                } catch (error) {

                    dispatch(setMiniLoading(false))
                    handleClick({ vertical: 'bottom', horizontal: 'center' })
                    setShowLinkBack(true)
                }

            } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");


            } else {
                handleClick({ vertical: 'bottom', horizontal: 'center' })
                setShowLinkBack(true)
            }
            dispatch(setMiniLoading(false))
        }


    }
    async function AddTelegramSession() {
        const BotSession = JSON.parse(localStorage.getItem('BotSession'))


        try {
            // const id = await TelegramDB.Data.put({
            //     'BotSession':BotSession,
            // });
            TelegramDB.Data.where({ PhoneNumber: PhoneNumber }).modify({ BotSession: BotSession })

            // TelegramDB.Data.where({PhoneNumber: PhoneNumber}).modify(item => ++item.quantity);


        } catch (error) {

        }
    }
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    const [ShowLinkBack, setShowLinkBack] = useState(false)
    return (
        <div className='JoinBotContainer'>
            <div className='JoinBot'>

                <div className='JoinBotHeader'>
                    <div>
                        <img src={JoinBotImage} />
                    </div>
                    <div className='JoinBotHeaderTitle'>
                        Join the bot
                    </div>
                    <div className='JoinBotHeaderDes'>
                        By tapping on Join button you will join Membersgram official bot in order to increase your account security
                    </div>
                </div>

                <div className='JoinBotFooter'>
                    {ShowLinkBack ? <Link to={`/LoginTelegram`} className='JoinBotFooterLink'>

                        Change the number
                    </Link> : null}

                    <button className='JoinBotFooterButton' onClick={() => JoinBotFunction()}>
                        {MiniLoading ?
                            <div className='LoaderContainer'>
                                <div className="loadminijoin"></div>
                            </div>

                            : 'Join and get started'}
                    </button>
                </div>

            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                // message={` Password changed`}
                key={vertical + horizontal}
                autoHideDuration={1000}
                message={
                    <span id="client-snackbar"> somthing went wrong</span>}

            />
        </div>
    )
}

export default JoinBot